import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Autocomplete, TextField } from "@mui/material";

import ADMIN_TEAM_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_TEAM_QUERY";
import { useDebounce } from "@sellernote/_shared/src/utils/common/hook";

import useTeamNameAutoCompleteOptions, {
  UserTeamNameListForAutoComplete,
} from "./useTeamNameAutoCompleteOptions";

function SearchTeamName({
  companyId,
  setCompanyId,
}: {
  companyId: number;
  setCompanyId: Dispatch<SetStateAction<number>>;
}) {
  const [searchTerm, setSearchTerm] = useState("");

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const { data: userData } = ADMIN_TEAM_QUERY.useGetAdminTeamNameList({
    name: !searchTerm ? undefined : debouncedSearchTerm,
  });

  const autoCompleteLabel = useMemo(() => {
    const companyData = userData?.find((v) => {
      return v.id === companyId;
    });

    if (!searchTerm && companyData) {
      return `${companyData.company}[${companyData.BRN}]`;
    }

    return searchTerm;
  }, [companyId, searchTerm, userData]);

  const autoCompleteValue = useMemo(() => {
    const companyData = userData?.find((v) => {
      return v.id === companyId;
    });

    if (!searchTerm && companyData) {
      return companyData.id;
    }

    return companyId;
  }, [companyId, searchTerm, userData]);

  const handleSearchTermChange = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, []);

  const { userTeamNameListForAutoComplete } = useTeamNameAutoCompleteOptions({
    userData,
    searchTerm,
  });

  const handlePurchasePartnerChange = useCallback(
    ({
      event,
      selectValue,
    }: {
      event: SyntheticEvent<Element, Event>;
      selectValue: UserTeamNameListForAutoComplete | null;
    }) => {
      setSearchTerm(selectValue?.label || "");
      setCompanyId(selectValue?.value || 0);
    },
    [setCompanyId]
  );

  return (
    <Autocomplete
      onChange={(event, newValue: UserTeamNameListForAutoComplete | null) =>
        handlePurchasePartnerChange({ event, selectValue: newValue })
      }
      value={{
        label: autoCompleteLabel,
        value: autoCompleteValue,
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      options={userTeamNameListForAutoComplete}
      sx={{ width: 300 }}
      renderInput={(params) => (
        <TextField {...params} onChange={handleSearchTermChange} />
      )}
    />
  );
}
export default SearchTeamName;
