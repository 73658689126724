import { useAtomValue } from "jotai";

import ADMIN_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_USER_QUERY";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";
import { FORWARDING_ADMIN_TRELLO_JOTAI_SELECTORS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/trello";
import ShipdaExportationTrello from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/trello/shipda/Exportation";

function ExportTrelloList() {
  const fetchTrelloListParams = useAtomValue(
    FORWARDING_ADMIN_TRELLO_JOTAI_SELECTORS.FETCH_TRELLO_LIST_PARAMS
  );

  const { region, corpSizeType, blKind, ...trelloParams } =
    fetchTrelloListParams;

  const {
    data: exportTrelloList,
    isLoading: isLoadingForGetExportTrelloList,
    refetch: refetchForGetExportTrelloList,
  } = TRELLO_BID_QUERY.useGetExportTrelloList({
    ...trelloParams,
    /** fetchTrelloListParams.region 값이 all 일때는, 국가 파라미터 전달을 하지 않아 KR, SG의뢰를 모두 불러옴 */
    ...(region === "all" ? [] : { region }),
    ...(corpSizeType === "all" ? [] : { corpSizeType }),
    ...(blKind === "all" ? [] : { blKind }),
    status: "inProgress",
    perPage: 300,
    serviceType: undefined,
  });

  const {
    data: exportTrelloFinishedList,
    isLoading: isLoadingForGetExportTrelloFinishedList,
    refetch: refetchForGetExportTrelloFinishedList,
  } = TRELLO_BID_QUERY.useGetExportTrelloFinishedList({
    ...trelloParams,
    /** fetchTrelloListParams.region 값이 all 일때는, 국가 파라미터 전달을 하지 않아 KR, SG의뢰를 모두 불러옴 */
    ...(region === "all" ? [] : { region }),
    ...(corpSizeType === "all" ? [] : { corpSizeType }),
    ...(blKind === "all" ? [] : { blKind }),
    status: "finished",
    projectStatus: "finished",
    perPage: 200,
    serviceType: undefined,
  });

  const {
    adminUserList,
    isLoading: isLoadingForGetAdminUserList,
    refetch: refetchForGetAdminUserList,
  } = ADMIN_USER_QUERY.useGetAdminUserList();

  const refetchData = () => {
    refetchForGetExportTrelloList();
    refetchForGetExportTrelloFinishedList();
    refetchForGetAdminUserList();
  };

  return (
    <Layout>
      <ShipdaExportationTrello
        trelloListData={exportTrelloList?.concat(
          exportTrelloFinishedList ?? []
        )}
        adminData={adminUserList}
        isLoading={
          isLoadingForGetExportTrelloList ||
          isLoadingForGetExportTrelloFinishedList ||
          isLoadingForGetAdminUserList
        }
        refetchData={refetchData}
      />
    </Layout>
  );
}

export default withRequireAuth(ExportTrelloList);
