import { useMemo } from "react";

import { GET_ADMIN_TEAM_NAME_LIST_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminTeam";
import { IS_UNDER_PRODUCTION } from "@sellernote/_shared/src/constants";

export interface UserTeamNameListForAutoComplete {
  label: string;
  value: number | null;
}

export default function useTeamNameAutoCompleteOptions({
  userData,
  searchTerm,
}: {
  userData: GET_ADMIN_TEAM_NAME_LIST_RES | undefined;
  searchTerm: string;
}) {
  const userTeamNameListForAutoComplete = useMemo(() => {
    if (!userData) {
      return [];
    }
    const userTeamNameList: UserTeamNameListForAutoComplete[] = userData.map(
      (userItem) => {
        return {
          label: `${userItem.company}[${userItem.BRN}]`,
          value: userItem.id,
        };
      }
    );
    // 부킹 커미션 명세서를 찾기 위해 서버에서 정해준 파트너를 추가 고도화 기획에서 제거 예정
    if (searchTerm.includes("페")) {
      return userTeamNameList.concat({ label: "페어콘", value: -1 });
    }

    if (IS_UNDER_PRODUCTION && searchTerm.includes("글")) {
      return userTeamNameList.concat({
        label: "글로브링크코리아",
        value: -2,
      });
    }

    if (!IS_UNDER_PRODUCTION && searchTerm.includes("G")) {
      return userTeamNameList.concat({
        label: "GlobelinkShanghai",
        value: -2,
      });
    }

    return userTeamNameList;
  }, [searchTerm, userData]);

  return { userTeamNameListForAutoComplete };
}
