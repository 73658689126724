import { ChangeEvent, useState } from "react";
import { useQueryClient } from "react-query";

import ADMIN_ORDER_QUERY, {
  ADMIN_ORDER_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_ORDER_QUERY";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

function useOrderCommentList({ poId }: { poId: number }) {
  const { mutate: updateOrderComment } = ADMIN_ORDER_QUERY.useUpdateComment();

  const queryClient = useQueryClient();

  const { handleSnackbarOpen } = useSnackbar();

  const [comment, setComment] = useState("");

  const handleCommentInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  };

  const handleCommentUpdate = () => {
    updateOrderComment(
      {
        poId: poId,
        body: comment,
      },

      {
        onSuccess: () => {
          setComment("");
          handleSnackbarOpen("요청에 성공했습니다.");
          queryClient.invalidateQueries(
            ADMIN_ORDER_QUERY_KEY_GEN.getAdminOrderDetail({
              id: poId,
            })
          );
        },
        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  return {
    handleCommentInputChange,
    handleCommentUpdate,
    comment,
  };
}

export default useOrderCommentList;
