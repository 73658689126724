import React from "react";
import { Divider, Switch } from "@mui/material";

import { PartnerFinancialAccountInfo as AccountInfo } from "@sellernote/_shared/src/types/forwarding/partnerManagement";
import { replaceEmptyToDash } from "@sellernote/_shared/src/utils/common/string";

const renderTargetWithDivider = (target: string) => {
  return (
    <React.Fragment>
      {target}
      <br />
      <Divider sx={{ background: "grey", mt: 0.4, mb: 0.4 }} />
    </React.Fragment>
  );
};

/**
 * 포맷팅을 생략할 조건이 만족되는지 확인하는 함수
 * 배열 요소가 하나거나, 마지막 요소는 포맷팅을 생략함
 * @param list[] - 검사할 목록
 * @param index - 현재 인덱스
 * @returns boolean - 포맷팅을 생략해야 하는지 여부
 */
const checkToSkipFormat = <T,>(list: T[], index: number) => {
  return list.length === 1 || index === list.length - 1;
};

const renderPaymentCondition = (financialAccountList: AccountInfo[]) => {
  if (!financialAccountList.length) return "-";

  const paymentConditionList = financialAccountList.map(
    ({ paymentCondition }, index) => {
      if (!paymentCondition) return "-";

      const condition = paymentCondition === "monthly" ? "월 정산" : "일 정산";

      if (checkToSkipFormat(financialAccountList, index)) return condition;

      return renderTargetWithDivider(condition);
    }
  );

  return paymentConditionList;
};

const renderBRN = (financialAccountList: AccountInfo[]) => {
  if (!financialAccountList.length) return "-";

  const BRN = financialAccountList.map(({ BRN }, index) => {
    const formattedBRN = BRN
      ? BRN.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3")
      : "-";

    if (checkToSkipFormat(financialAccountList, index)) return formattedBRN;

    return renderTargetWithDivider(formattedBRN);
  });

  return BRN;
};

const renderBRNFile = (financialAccountList: AccountInfo[]) => {
  if (!financialAccountList.length) return "-";

  const BRNFileList = financialAccountList.map(({ files }) => {
    return files
      .filter(({ domain }) => domain === "financialAccountBRN")
      .map(({ name }, index) => {
        const formattedBRNName = replaceEmptyToDash(name);

        if (checkToSkipFormat(BRNFileList, index)) return formattedBRNName;

        return renderTargetWithDivider(formattedBRNName);
      });
  });

  return BRNFileList;
};

const renderBank = (financialAccountList: AccountInfo[]) => {
  if (!financialAccountList.length) return "-";

  const bankList = financialAccountList.map(({ bankMetadata }, index) => {
    const formattedBank = replaceEmptyToDash(bankMetadata);

    if (checkToSkipFormat(financialAccountList, index)) return formattedBank;

    return renderTargetWithDivider(formattedBank);
  });

  return bankList;
};

const renderBankAccount = (financialAccountList: AccountInfo[]) => {
  if (!financialAccountList.length) return "-";

  const bankAccountList = financialAccountList.map(({ bankAccount }, index) => {
    const formattedBankAccount = replaceEmptyToDash(bankAccount);

    if (checkToSkipFormat(financialAccountList, index)) {
      return formattedBankAccount;
    }

    return renderTargetWithDivider(formattedBankAccount);
  });

  return bankAccountList;
};

const renderIsInvoiceIssued = (issuesInvoice: boolean) => {
  return (
    <span style={{ color: issuesInvoice ? "#358fe8" : "inherit" }}>
      {issuesInvoice ? "발행" : "미발행"}
    </span>
  );
};

const renderTransactionFlag = (isFinancialAccount: boolean) => {
  return (
    <span style={{ color: isFinancialAccount ? "#358fe8" : "inherit" }}>
      {isFinancialAccount ? "O" : "X"}
    </span>
  );
};

const renderActivateCheckbox = ({
  isDeactivated,
  onPartnerDeactivate,
  partnerId,
}: {
  isDeactivated: boolean;
  onPartnerDeactivate: (partnerId: number, isDeactivated: boolean) => void;
  partnerId: number;
}) => {
  return (
    <Switch
      size="small"
      onClick={(e) => e.stopPropagation()}
      onChange={() => {
        onPartnerDeactivate(partnerId, isDeactivated);
      }}
      checked={!isDeactivated}
    />
  );
};

const getHeadCellList = (showsToggle: boolean) => {
  return [
    {
      id: "id",
      disablePadding: false,
      label: "ID",
      width: 80,
    },
    ...(showsToggle
      ? [{ id: "어드민", disablePadding: false, label: "어드민", width: 50 }]
      : []),
    {
      id: "회사명",
      disablePadding: false,
      label: "회사명",
      width: 230,
    },
    {
      id: "수출입 구분",
      disablePadding: false,
      label: "수출입 구분",
      width: 120,
    },
    {
      id: "언어",
      disablePadding: false,
      label: "언어",
      width: 100,
    },
    {
      id: "담당 국가",
      disablePadding: false,
      label: "담당 국가",
      width: 200,
    },
    {
      id: "운송 유형",
      disablePadding: false,
      label: "운송 유형",
      width: 200,
    },
    {
      id: "담당자 이름",
      disablePadding: false,
      label: "담당자 이름",
      width: 150,
    },
    {
      id: "직함",
      disablePadding: false,
      label: "직함",
      width: 150,
    },
    {
      id: "전화번호",
      disablePadding: false,
      label: "전화번호",
      width: 200,
    },
    {
      id: "이메일",
      disablePadding: false,
      label: "이메일",
      width: 250,
    },
    {
      id: "정산 유형",
      disablePadding: false,
      label: "정산 유형",
      width: 150,
    },
    {
      id: "금전거래 여부",
      disablePadding: false,
      label: "금전거래 여부",
      width: 120,
    },
    {
      id: "사업자 등록번호",
      disablePadding: false,
      label: "사업자 등록번호",
      width: 250,
    },
    {
      id: "사업자 등록증",
      disablePadding: false,
      label: "사업자 등록증",
      width: 200,
    },
    {
      id: "은행",
      disablePadding: false,
      label: "은행",
      width: 250,
    },
    {
      id: "계좌번호",
      disablePadding: false,
      label: "계좌번호",
      width: 200,
    },
    {
      id: "계산서 발행",
      disablePadding: false,
      label: "계산서 발행",
      width: 100,
    },
    {
      id: "활성화",
      disablePadding: false,
      label: "활성화",
      width: 100,
    },
    {
      id: "삭제",
      disablePadding: false,
      label: "삭제",
      width: 100,
    },
  ];
};

export {
  renderPaymentCondition,
  renderBRN,
  renderBRNFile,
  renderBank,
  renderBankAccount,
  renderIsInvoiceIssued,
  renderTransactionFlag,
  renderActivateCheckbox,
  getHeadCellList,
};
