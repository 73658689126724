import { useParams } from "react-router-dom";
import { useSetAtom } from "jotai";

import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_COMMON_QUERY";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { LegacyShipmentType } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { InvoiceType } from "@sellernote/_shared/src/types/forwarding/trello";
import {
  getExchangeDate,
  getInvoiceExchangeRateList,
  getInvoiceTypeKR,
} from "@sellernote/_shared/src/utils/forwarding/admin/tradingStatement";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import { FORWARDING_ADMIN_TRADING_STATEMENT_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/tradingStatement";

import useSessionInvoiceId from "./TradingStatementInfo/hooks/useSessionInvoiceId";

import TradingStatementInfo from "./TradingStatementInfo";

const TradingStatement = () => {
  const { bidId, invoiceType, shipmentType } = useParams<{
    bidId: string;
    invoiceType: InvoiceType;
    shipmentType: LegacyShipmentType;
  }>();

  const setDefaultExchangeRateList = useSetAtom(
    FORWARDING_ADMIN_TRADING_STATEMENT_ATOMS.TRADING_STATEMENT_DEFAULT_EXCHANGE_RATE_LIST
  );

  const { sessionInvoiceId, handleSessionInvoiceIdChange } =
    useSessionInvoiceId();

  const { data: bidDetailData } = TRELLO_BID_QUERY.useGetTrelloBidDetail({
    bidId: Number(bidId),
    enabled: shipmentType === "import",
  });

  const { data: exportShipmentDetailData } =
    TRELLO_BID_QUERY.useGetExportTrelloDetail({
      bidId: Number(bidId),
      enabled: shipmentType === "export",
    });

  const { data: portData } = ADMIN_COMMON_QUERY.useGetPortList();

  const { data: countryList } = ADMIN_COMMON_QUERY.useGetCountryList();

  const { data: invoiceData } = ADMIN_BID_QUERY.useGetAdminInvoice({
    invoiceId: Number(sessionInvoiceId) || undefined,
  });

  const shipmentDetailData =
    shipmentType === "export" ? exportShipmentDetailData : bidDetailData;

  /** 초기 셋팅 시 불러오는 환율 데이터 */
  const { data: initialExchangeRateList } =
    ADMIN_COMMON_QUERY.useGetExchangeRateList(
      {
        all: true,
        date: getExchangeDate({ invoiceData, shipmentDetailData }),
        countryTarget: "KR",
      },
      /**
       * 환율 요청에 의뢰상세와 거래명세서 데이터가 필요해 enabled 설정을 추가
       * - 요청이 가능한 경우
       *   - 의뢰 데이터가 있지만 거래명세서 아이디가 없는 경우
       *   - 의뢰 데이터가 있고 거래명세서 아이디와 거래명세서 데이터가 있는 경우
       */
      shipmentDetailData !== undefined &&
        ((typeof sessionInvoiceId === "string" && invoiceData !== undefined) ||
          !sessionInvoiceId),

      /**
       * 요청 성공 시 환율 기준값을 전역데이터로 셋팅
       */
      (data) => {
        setDefaultExchangeRateList(
          getInvoiceExchangeRateList({
            invoiceType,
            exchangeRateData: data,
            invoiceData,
          })
        );
      }
    );

  if (
    !shipmentDetailData ||
    !portData ||
    !countryList ||
    (sessionInvoiceId && !invoiceData) ||
    !initialExchangeRateList
  ) {
    return (
      <Layout breadcrumbs={["입찰 관리"]} title={"거래명세서 생성"}>
        <Loading active={true}></Loading>
      </Layout>
    );
  }

  return (
    <Layout
      breadcrumbs={["입찰 관리"]}
      title={`${getInvoiceTypeKR(invoiceType)} 생성 ${bidId}`}
    >
      <TradingStatementInfo
        bidDetailData={shipmentDetailData}
        portData={portData}
        countryList={countryList}
        invoiceData={invoiceData}
        initialExchangeRateList={initialExchangeRateList}
        sessionInvoiceId={sessionInvoiceId}
        onSessionInvoiceChange={handleSessionInvoiceIdChange}
        isImport={shipmentType === "import"}
      />
    </Layout>
  );
};

export default TradingStatement;
