import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { useRecoilState } from "recoil";
import { SHIPDA_ADMIN_FILTER_ATOMS } from "states/filters";

import useGetCSVDownloadInfoFromQuery from "@sellernote/_shared/src/hooks/common/useGetCSVDownloadInfoFromQuery";
import BUSINESS_MANAGEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/BUSINESS_MANAGEMENT_QUERY";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";

import BusinessManagementExtraTable from "./BusinessManagementExtraTable";
import BusinessManagementOperationTable from "./BusinessManagementOperationTable";
import useOperationTablePanel from "./BusinessManagementOperationTable/useOperationTabelPanel";
import useTableHeadPanel from "./BusinessManagementOperationTable/useTableHeadPanel";

const Operation = () => {
  const [filterData, setFilterData] = useRecoilState(
    SHIPDA_ADMIN_FILTER_ATOMS.ADMIN_OPERATION_FILTER_LIST
  );

  const [currentPage, setCurrentPage] = useState(0);

  const [perPage, setPerPage] = useState(20);

  const {
    debouncedSearchTermWithObject,
    dateFilterData,
    etdDateFilterData,
    TablePanel,
    countryName,
    polId,
    podId,
  } = useOperationTablePanel({
    key: "operationTableFilter",
  });

  const startSearchBooleanMemo = useMemo(() => {
    if (dateFilterData || etdDateFilterData) {
      return true;
    }
    return false;
  }, [dateFilterData, etdDateFilterData]);

  const countryNameMemo = useMemo(() => {
    return countryName.trim() ? { country: countryName } : null;
  }, [countryName]);

  const startPortIdMemo = useMemo(() => {
    return polId ? { startPortId: polId } : null;
  }, [polId]);

  const endPortIdMemo = useMemo(() => {
    return podId ? { endPortId: podId } : null;
  }, [podId]);

  const { TableHeadPanel, tableHeadFilterData: operationHeadFilterData } =
    useTableHeadPanel();

  const saveFilterDataRefCallback = useCallback(() => {
    setFilterData({
      ...operationHeadFilterData,
      ...dateFilterData,
      ...etdDateFilterData,
      ...debouncedSearchTermWithObject,
      ...operationHeadFilterData,
      ...countryNameMemo,
      ...startPortIdMemo,
      ...endPortIdMemo,
    });
  }, [
    operationHeadFilterData,
    countryNameMemo,
    startPortIdMemo,
    endPortIdMemo,
    dateFilterData,
    debouncedSearchTermWithObject,
    etdDateFilterData,
    setFilterData,
  ]);

  const { data: operationList, isLoading } =
    BUSINESS_MANAGEMENT_QUERY.useGetOperationList({
      enabled: startSearchBooleanMemo,
      page: currentPage,
      perPage,
      ...filterData,
      isExcel: false,
    });

  const {
    isCSVDownloadRequested,
    setIsCSVDownloadRequested,
    dataForCSVDownload,
    ResponseHandlerOfFetchDataForCSVDownload,
    removeQueryOfFetchDataForCSVDownload,
    statusOfQueryOfFetchDataForCSVDownload,
  } = useGetCSVDownloadInfoFromQuery({
    query: BUSINESS_MANAGEMENT_QUERY.useGetOperationExcelList,
    queryArgs: [
      {
        page: currentPage,
        perPage,
        ...filterData,
        isExcel: true,
      },
    ],
  });

  return (
    <Layout title="운영/정산 현황">
      <Box sx={{ background: "#fff", p: 1 }}>{TablePanel}</Box>

      <BusinessManagementOperationTable
        operationList={operationList}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPage={perPage}
        setPerPage={setPerPage}
        TableHeadPanel={TableHeadPanel}
        saveFilterDataRefCallback={saveFilterDataRefCallback}
        csvDownloadProps={{
          isCSVDownloadRequested,
          setIsCSVDownloadRequested,
          dataForCSVDownload,
          ResponseHandlerOfFetchDataForCSVDownload,
          removeQueryOfFetchDataForCSVDownload,
          statusOfQueryOfFetchDataForCSVDownload,
        }}
        isLoading={isLoading}
      />

      <BusinessManagementExtraTable
        operationList={operationList?.searchTotal}
      />
    </Layout>
  );
};

export default withRequireAuth(Operation);
