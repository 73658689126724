import { Box, InputLabel, Typography } from "@mui/material";

import { CREATE_PARTNER_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/partnerManagement";
import { FileTypes } from "@sellernote/_shared/src/types/common/upload";
import { AccountDetailFile } from "@sellernote/_shared/src/types/forwarding/adminAccount";

import useHandleAccountList from "./hooks";

import AccountAutoComplete from "./AccountAutoComplete";
import FileUploadButton from "./FileUploadButton";

export default function AccountInfo({
  form,
  isSelected,
  setIsSelected,
  isCreate,
  setIsCreate,
  onAutoCompleteSelect,
  onFileUploadModalOpen,
  BRNFileList,
  bankBookCopyfileList,
  requiresAccountInfo,
}: {
  form: CREATE_PARTNER_REQ;
  isSelected: boolean;
  setIsSelected: (val: boolean) => void;
  isCreate: boolean;
  setIsCreate: (val: boolean) => void;
  onAutoCompleteSelect: (val: string | null, isNew?: boolean) => void;
  onFileUploadModalOpen: (domain: AccountDetailFile["domain"]) => void;
  BRNFileList: FileTypes[];
  bankBookCopyfileList: FileTypes[];
  requiresAccountInfo: boolean;
}) {
  const { optionList } = useHandleAccountList(
    form.targetAccountPayloads[0].BRN
  );

  return (
    <Box
      border={1}
      borderTop={"none"}
      display={"flex"}
      flexDirection={"column"}
      borderColor={"#0000001f"}
      mt={-1}
    >
      <Box display={"flex"} flexDirection={"column"}>
        <Box ml={-1}>
          <InputLabel>
            사업자 등록번호{" "}
            {requiresAccountInfo && (
              <Typography component={"span"} color="red">
                *
              </Typography>
            )}
          </InputLabel>

          <AccountAutoComplete
            optionList={optionList}
            form={form}
            setIsSelected={setIsSelected}
            isCreate={isCreate}
            setIsCreate={setIsCreate}
            onAutoCompleteSelect={onAutoCompleteSelect}
          />
        </Box>

        <FileUploadButton
          isSelected={isSelected}
          onFileUploadModalOpen={onFileUploadModalOpen}
          BRNFileList={BRNFileList}
          bankBookCopyfileList={bankBookCopyfileList}
          requiresAccountInfo={requiresAccountInfo}
        />
      </Box>
    </Box>
  );
}
