import { AlertColor } from "@mui/material";

import { CREATE_PARTNER_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/partnerManagement";
import { AccountFileRef } from "@sellernote/_shared/src/types/forwarding/adminAccount";
import { getEmailValidation } from "@sellernote/_shared/src/utils/common/validation";

function validateCreatePartner(
  form: CREATE_PARTNER_REQ,
  openSnackbar: (message: string, color?: AlertColor) => void,
  requiresAccountInfo: boolean
) {
  if (!form.name) {
    openSnackbar("회사명을 입력해주세요.", "warning");
    return false;
  }

  if (!form.businessArea) {
    openSnackbar("파트너 유형을 선택해주세요.", "warning");
    return false;
  }

  if (!form.language) {
    openSnackbar("언어를 선택해주세요.", "warning");
    return false;
  }

  if (!form.managerName) {
    openSnackbar("담당자명을 입력해주세요.", "warning");
    return false;
  }

  if (!form.managerEmail) {
    openSnackbar("이메일을 입력해주세요.", "warning");
    return false;
  }

  if (!getEmailValidation(form.managerEmail).result) {
    openSnackbar("이메일 형식이 올바르지 않습니다.", "warning");
    return false;
  }

  if (requiresAccountInfo && !form.targetAccountPayloads[0].BRN) {
    openSnackbar("사업자 등록번호를 입력 또는 선택해주세요.", "warning");
    return false;
  }

  if (requiresAccountInfo && form.targetAccountPayloads[0].BRN.length !== 12) {
    openSnackbar("정상적인 사업자 등록번호가 맞는지 확인해주세요.", "warning");
    return false;
  }

  if (form.address && form.address.length > 200) {
    openSnackbar("주소는 200자 이하로 입력해주세요.", "warning");
    return false;
  }

  if (form.shipmentTypes.length === 0) {
    openSnackbar("수입/수출을 선택해주세요.", "warning");
    return false;
  }

  return true;
}

/** 파트너 등록 요청 API payload에 맞게 상태를 가공하는 함수 */
function setPayload({
  form,
  isSelected,
  timeStamp,
}: {
  form: CREATE_PARTNER_REQ;
  isSelected: boolean;
  timeStamp: number;
}) {
  const {
    name,
    businessArea,
    language,
    managerName,
    managerEmail,
    isFinancial,
    invoiceIssueFlag,
    targetAccountPayloads,
    countries,
    transportModes,
    managerPhone,
    managerPosition,
    address,
    shipmentTypes,
  } = form;

  const fileList: AccountFileRef[] = [
    { domain: "financialAccountBRN", targetId: timeStamp },
    { domain: "financialAccountBankBookCopy", targetId: timeStamp },
  ];

  /** 파트너 등록 > 거래처 선택시 첨부파일을 payload에 추가 */
  const fileRefs = isSelected ? null : fileList;

  const BRNWithoutHyphen = targetAccountPayloads[0].BRN.split("-").join("");

  const requiredField = {
    name,
    businessArea,
    language,
    managerName,
    managerEmail,
    isFinancial,
    invoiceIssueFlag,
    shipmentTypes,
    targetAccountPayloads: form.isFinancial
      ? [{ BRN: BRNWithoutHyphen, fileRefs }]
      : [],
  };

  const optionalField = {
    ...(isSelected ? {} : { id: timeStamp }),
    ...(countries?.length ? { countries } : {}),
    ...(transportModes?.length ? { transportModes } : {}),
    ...(form.managerPhone ? { managerPhone } : {}),
    ...(form.managerPosition ? { managerPosition } : {}),
    ...(form.address ? { address } : {}),
  };

  return { ...requiredField, ...optionalField };
}

function formatBRN(val: string | null) {
  if (!val) return null;

  return val.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
}

export { validateCreatePartner, setPayload, formatBRN };
