import { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";

import ADMIN_TEAM_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_TEAM_QUERY";
import { POProjectStatus } from "@sellernote/_shared/src/types/forwarding/adminOrder";
import Modal from "@sellernote/_shared-for-forwarding-admin/src/components/Modal";
import useMuiSelect from "@sellernote/_shared-for-forwarding-admin/src/hooks/useMuiSelect";

import useCountingModalBody from "../useCountingModalBody";

function TeamMemberSelectModal({
  opensTeamMemberSelectModal,
  setOpensTeamMemberSelectModal,
  teamName,
  teamId,
  POId,
  POProjectStatus,
  hasSubPO,
  isOpenApiAuth,
}: {
  opensTeamMemberSelectModal: boolean;
  setOpensTeamMemberSelectModal: (value: boolean) => void;
  teamName: string;
  teamId: number;
  POId: number;
  POProjectStatus: POProjectStatus;
  hasSubPO: boolean;
  isOpenApiAuth: boolean;
}) {
  const [showsCountingModal, setShowsCountingModalBody] = useState(false);

  const { data: teamMemberList, refetch: refetchTeamMemberList } =
    ADMIN_TEAM_QUERY.useGetAdminTeamMemberList(teamId);

  const getTeamSelectOptions = () => {
    if (!teamMemberList) {
      return [];
    }

    return teamMemberList.map((v) => {
      return { label: `${v.userName} (id:${v.userId})`, value: v.userId };
    });
  };

  const { selectedValue: teamMemberSelectValue, MuiSelect: TeamMemberSelect } =
    useMuiSelect({
      options: getTeamSelectOptions(),
      title: "담당자 선택",
      fullWidth: true,
    });

  const handleBidCreate = () => {
    setShowsCountingModalBody(true);
  };

  const { ModalBody } = useCountingModalBody({
    setShowsCountingModal: setOpensTeamMemberSelectModal,
    POId,
    name: teamName,
    userId: teamMemberSelectValue as number,
    POProjectStatus,
    hasSubPO,
    teamId,
    isOpenApiAuth,
  });

  return (
    <Modal
      isOpened={opensTeamMemberSelectModal}
      handleClose={() => setOpensTeamMemberSelectModal(false)}
      modalBody={
        showsCountingModal ? (
          ModalBody
        ) : (
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="body2">
                팀으로 생성한 발주입니다. 운송의뢰를 배정할 담당자를 선택하세요.
              </Typography>
            </Grid>

            <Grid item>{TeamMemberSelect}</Grid>

            <Grid item container justifyContent={"center"}>
              <Grid item>
                <Button variant="contained" onClick={handleBidCreate}>
                  확인
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )
      }
    ></Modal>
  );
}

export default TeamMemberSelectModal;
