import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { Button, CircularProgress } from "@mui/material";
import { blue } from "@mui/material/colors";
import { AxiosResponse } from "axios";

import {
  RequestInvoiceData,
  SAVE_INVOICE_RES,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminFile";
import { ADMIN_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_FILE_QUERY";
import { ResponseFailureInfo } from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import {
  InvoiceType,
  TrelloBidDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

import useCompareWithDefaultValue from "../../../hooks/useCompareWithDefaultValue";
import useCommonErrorMessage from "../hooks/useCommonErrorMessage";

function TemporarySaveButton({
  invoiceType,
  getInvoiceDataForRequest,
  handlerErrorRequest,
  shipmentDetailData,
  invoiceState,
  directAirRton,
  sessionInvoiceId,
  onSessionInvoiceChange,
}: {
  invoiceType: InvoiceType;
  getInvoiceDataForRequest: () => RequestInvoiceData;
  handlerErrorRequest: (
    response: AxiosResponse<ResponseFailureInfo, any> | undefined
  ) => void;
  shipmentDetailData: TrelloBidDetail;
  invoiceState: ApplyBidFormData;
  directAirRton: number;
  sessionInvoiceId: string | null;
  onSessionInvoiceChange: (invoiceId: string) => void;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutate: saveInvoice, isLoading: saveInvoiceLoading } =
    ADMIN_FILE_QUERY.useSaveInvoice();

  const { mutate: editInvoice, isLoading: editInvoiceLoading } =
    ADMIN_FILE_QUERY.useEditInvoice();

  const { isCargoInfoUnchanged, isExchangeInfoUnchanged } =
    useCompareWithDefaultValue({
      invoiceState,
      shipmentDetailData,
      directAirRton,
    });

  const { checkAndShowCommonErrorMessage } = useCommonErrorMessage(true);

  const handleRequestSuccess = useCallback(
    ({ data }: { data: SAVE_INVOICE_RES }) => {
      if (sessionInvoiceId) {
        queryClient.invalidateQueries(
          ADMIN_BID_QUERY_KEY_GEN.getAdminInvoice({
            invoiceId: Number(sessionInvoiceId),
          })
        );
      } else {
        onSessionInvoiceChange(data.id.toString());
      }

      handleSnackbarOpen("임시저장했습니다.");
    },
    [sessionInvoiceId, handleSnackbarOpen, queryClient, onSessionInvoiceChange]
  );

  const handleTemporarySaveClick = useCallback(() => {
    if (
      checkAndShowCommonErrorMessage({
        isCargoInfoUnchanged,
        isExchangeInfoUnchanged,
        hasDirectAirRton:
          shipmentDetailData.freightType === "AIR" && !directAirRton,
      })
    ) {
      return;
    }

    if (sessionInvoiceId) {
      editInvoice(
        {
          pathParams: {
            invoiceId: Number(sessionInvoiceId),
          },
          invoiceData: getInvoiceDataForRequest(),
          isTemporary: true,
          invoiceType,
        },
        {
          onSuccess: handleRequestSuccess,
          onError: ({ response }) => {
            handlerErrorRequest(response);
          },
        }
      );
    } else {
      saveInvoice(
        {
          invoiceData: getInvoiceDataForRequest(),
          isTemporary: true,
          invoiceType,
        },
        {
          onSuccess: handleRequestSuccess,
          onError: ({ response }) => {
            handlerErrorRequest(response);
          },
        }
      );
    }
  }, [
    editInvoice,
    checkAndShowCommonErrorMessage,
    getInvoiceDataForRequest,
    handleRequestSuccess,
    handlerErrorRequest,
    invoiceType,
    isCargoInfoUnchanged,
    isExchangeInfoUnchanged,
    saveInvoice,
    sessionInvoiceId,
  ]);

  return (
    <Button
      disabled={saveInvoiceLoading || editInvoiceLoading}
      variant="contained"
      onClick={handleTemporarySaveClick}
      sx={{
        backgroundColor: blue[300],
        "&:hover": {
          backgroundColor: blue[400], // 호버 시 좀 더 진한 파란색
        },
      }}
    >
      {saveInvoiceLoading || editInvoiceLoading ? (
        <CircularProgress size={25} />
      ) : (
        "임시저장"
      )}
    </Button>
  );
}

export default TemporarySaveButton;
