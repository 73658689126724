import { useState } from "react";
import { useQueryClient } from "react-query";
import axios from "axios";

import { GET_PRESIGNED_URL_RES_DATA } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminFile";
import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_FILE_QUERY";
import { ADMIN_PROMOTION_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_PROMOTION_QUERY";
import { FileTypes } from "@sellernote/_shared/src/types/common/upload";
import { AdminPromotionDetail } from "@sellernote/_shared/src/types/forwarding/adminPromotion";
import FileUpload from "@sellernote/_shared-for-forwarding-admin/src/components/FileUpload";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

const PromotionFileUpload = ({
  detailData,
}: {
  detailData: AdminPromotionDetail;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const [fileList, setFileList] = useState<FileTypes[]>([]);

  const { mutate: getPresignedURL } =
    ADMIN_FILE_QUERY.useGetNoPermissionPresignedURL();

  const { mutate: bindFileAfterUpload } =
    ADMIN_FILE_QUERY.useBindFileAfterUpload();

  const handleFileUpload = () => {
    const requestFileData = fileList.map((v: FileTypes) => {
      return {
        mimeType: v.fileInfo.type,
        name: v.fileInfo.name,
        type: "promotion",
      };
    });

    const flatS3Data = fileList.flat();

    getPresignedURL(
      {
        presignedUrlOptions: requestFileData,
        isPublic: false,
      },
      {
        onSuccess: async ({ data: successData }) => {
          let index = 0;
          for await (const param of successData) {
            await axios.put(param.url, flatS3Data[index].fileInfo, {
              headers: { "Content-Type": flatS3Data[index].fileInfo.type },
            });
            index += 1;
          }

          const bindingData = successData.map(
            (v: GET_PRESIGNED_URL_RES_DATA) => {
              return {
                key: v.key,
                domain: "promotion",
                targetId: detailData.id,
              };
            }
          );

          bindFileAfterUpload(
            { bindList: bindingData },
            {
              onSuccess: () => {
                setFileList([]);
                handleSnackbarOpen("파일 업로드에 성공했습니다.");
                queryClient.invalidateQueries(
                  ADMIN_PROMOTION_QUERY_KEY_GEN.all()
                );
              },
              onError: () => {
                handleSnackbarOpen("파일 업로드에 실패했습니다.", "error");
              },
            }
          );
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  return (
    <FileUpload
      isMultiple={true}
      files={fileList}
      setFiles={setFileList}
      handleUpload={handleFileUpload}
    />
  );
};

export default PromotionFileUpload;
