import React, { useCallback } from "react";
import axios from "axios";

import { GET_PRESIGNED_URL_RES_DATA } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminFile";
import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_FILE_QUERY";
import { FileTypes } from "@sellernote/_shared/src/types/common/upload";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";
import FileUpload from "@sellernote/_shared-for-forwarding-admin/src/components/FileUpload";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

const FileUploadModal = ({
  fileList,
  setFileList,
  showsUploadModal,
  onModalClose,
  type,
  isPermission,
  targetId,
}: {
  fileList: FileTypes[];
  setFileList: React.Dispatch<React.SetStateAction<FileTypes[]>>;
  showsUploadModal: boolean;
  onModalClose: () => void;
  type: string;
  isPermission: boolean;
  targetId: number;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const { mutate: getPresignedURL } = ADMIN_FILE_QUERY.useGetPresignedURL({
    bidId: targetId,
    isPermission: isPermission,
  });

  const { mutate: bindFileAfterUpload } =
    ADMIN_FILE_QUERY.useBindFileAfterUpload();

  const uploadFile = useCallback(() => {
    const requestFileData = fileList.map((v: FileTypes) => {
      return {
        mimeType: v.fileInfo.type,
        name: v.fileInfo.name,
        type,
      };
    });

    const flatS3Data = fileList.flat();

    getPresignedURL(
      {
        presignedUrlOptions: requestFileData,
        isPublic: false,
        targetId: targetId,
      },
      {
        onSuccess: async ({ data: successData }) => {
          let index = 0;

          for await (const param of successData) {
            await axios.put(param.url, flatS3Data[index].fileInfo, {
              headers: { "Content-Type": flatS3Data[index].fileInfo.type },
            });
            index += 1;
          }

          const bindingData = successData.map(
            (v: GET_PRESIGNED_URL_RES_DATA) => {
              return {
                key: v.key,
                domain: type,
                targetId,
              };
            }
          );

          bindFileAfterUpload(
            { bindList: bindingData },
            {
              onSuccess: () => {
                handleSnackbarOpen("파일 업로드에 성공했습니다.");
                onModalClose();
              },
              onError: () => {
                handleSnackbarOpen("파일 업로드에 실패했습니다.", "error");
                setFileList([]);
              },
            }
          );
        },

        onError: () => {
          handleSnackbarOpen("파일 업로드에 실패했습니다.", "error");
        },
      }
    );
  }, [
    fileList,
    getPresignedURL,
    targetId,
    type,
    bindFileAfterUpload,
    handleSnackbarOpen,
    onModalClose,
    setFileList,
  ]);

  return (
    <Modal
      handleClose={onModalClose}
      isOpened={showsUploadModal}
      showsCloseIcon={true}
      modalBody={
        <FileUpload
          showsFileExtensionMessage={true}
          isMultiple={false}
          files={fileList}
          setFiles={setFileList}
          handleUpload={uploadFile}
        />
      }
    />
  );
};

export default FileUploadModal;
