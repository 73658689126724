import { UseFormSetValue } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useParams } from "react-router-dom";
import WarningIcon from "@mui/icons-material/Warning";
import { Box, Button, Typography } from "@mui/material";
import {
  DatePicker as MUIDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { InvoiceData } from "@sellernote/_shared/src/types/forwarding/tradingStatement";
import {
  ExchangeRate,
  InvoiceType,
  TrelloBidDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";
import {
  toFormattedDate,
  toFormattedDateToUTCDate,
  transformUTCDateToLocalDateTime,
} from "@sellernote/_shared/src/utils/common/date";
import { checkExchangeModification } from "@sellernote/_shared/src/utils/forwarding/admin/tradingStatement";

import useCompareWithDefaultValue from "../../hooks/useCompareWithDefaultValue";

import DescriptionGridBox from "../components/DescriptionGridBox";
import NoLabelTextField from "../components/NoLabelTextField";

import useExchangeInfoLogic from "./useExchangeInfoLogic";

function ExchangeInfo({
  invoiceState,
  shipmentDetailData,
  setValue,
  invoiceData,
  initialExchangeList,
  directAirRton,
  handleExchangeRateDate,
  exchangeRateDate,
}: {
  invoiceState: ApplyBidFormData;
  shipmentDetailData: TrelloBidDetail;
  setValue: UseFormSetValue<ApplyBidFormData>;
  invoiceData: InvoiceData | undefined;
  initialExchangeList: ExchangeRate[];
  directAirRton: number;
  handleExchangeRateDate: (date: string) => void;
  exchangeRateDate: string;
}) {
  const { invoiceType } = useParams<{
    invoiceType: InvoiceType;
  }>();

  const {
    handleApplyExchangeInfoClick,
    handleResetExchangeInfoClick,
    handleExchangeRateListChange,
    differentCurrencyListFromDynamicExchangeRate,
    defaultExchangeRateList,
    isDifferentFromDynamicExchangeRate,
    setIsUnchangedExchangeRateDate,
    isResetButtonDisabled,
  } = useExchangeInfoLogic({
    invoiceState,
    shipmentDetailData,
    setValue,
    invoiceData,
    initialExchangeList,
    directAirRton,
    handleExchangeRateDate,
    exchangeRateDate,
  });

  const { isExchangeInfoUnchanged } = useCompareWithDefaultValue({
    invoiceState,
    shipmentDetailData,
    directAirRton,
  });

  return (
    <DescriptionGridBox
      title={"환율 정보"}
      warningMessage={
        isDifferentFromDynamicExchangeRate ? (
          <Box display={"flex"} alignItems={"center"}>
            <WarningIcon fontSize="small" />

            {invoiceType === "refund" ? (
              <Typography variant="body2">
                부킹 커미션 명세서에서 USD는 1000으로 고정됩니다.
              </Typography>
            ) : (
              <Typography variant="body2">
                {"아래 환율(" +
                  `${differentCurrencyListFromDynamicExchangeRate?.toString()}` +
                  ")이 “환율 기준일” 의 환율과 다릅니다. “환율 기준일” 과 별개로 환율을 직접 수정하셨기 때문입니다."}
              </Typography>
            )}
          </Box>
        ) : undefined
      }
      descriptionValueList={[
        {
          label: "USD",
          labelGridSize: 3,
          gridSize: 1.2,
          value: (
            <NumericFormat
              thousandSeparator={true}
              decimalScale={2}
              customInput={NoLabelTextField}
              name="USD"
              value={
                invoiceState.invoiceExchangeRateList.find(
                  (v) => v.currency === "USD"
                )?.rate || ""
              }
              onValueChange={(value) => {
                handleExchangeRateListChange(value?.floatValue || 0, "USD");
              }}
              fullWidth
              size="small"
              sx={{
                "& .MuiInputBase-input": {
                  color: checkExchangeModification({
                    currency: "USD",
                    defaultExchangeRateList,
                    invoiceState,
                  })
                    ? "#2196f3"
                    : "inherit",
                },
              }}
            />
          ),
        },
        {
          label: "EUR",
          labelGridSize: 3,
          gridSize: 1.2,
          value: (
            <NumericFormat
              thousandSeparator={true}
              decimalScale={2}
              customInput={NoLabelTextField}
              name="EUR"
              value={
                invoiceState.invoiceExchangeRateList.find(
                  (v) => v.currency === "EUR"
                )?.rate || ""
              }
              onValueChange={(value) => {
                handleExchangeRateListChange(value?.floatValue || 0, "EUR");
              }}
              fullWidth
              size="small"
              sx={{
                "& .MuiInputBase-input": {
                  color: checkExchangeModification({
                    currency: "EUR",
                    defaultExchangeRateList,
                    invoiceState,
                  })
                    ? "#2196f3"
                    : "inherit",
                },
              }}
            />
          ),
        },
        {
          label: "CNY",
          labelGridSize: 3,
          gridSize: 1.2,
          value: (
            <NumericFormat
              thousandSeparator={true}
              decimalScale={2}
              customInput={NoLabelTextField}
              name="CNY"
              value={
                invoiceState.invoiceExchangeRateList.find(
                  (v) => v.currency === "CNY"
                )?.rate || ""
              }
              onValueChange={(value) => {
                handleExchangeRateListChange(value?.floatValue || 0, "CNY");
              }}
              fullWidth
              size="small"
              sx={{
                "& .MuiInputBase-input": {
                  color: checkExchangeModification({
                    currency: "CNY",
                    defaultExchangeRateList,
                    invoiceState,
                  })
                    ? "#2196f3"
                    : "inherit",
                },
              }}
            />
          ),
        },
        {
          label: "GBP",
          labelGridSize: 3,
          gridSize: 1.2,
          value: (
            <NumericFormat
              thousandSeparator={true}
              decimalScale={2}
              customInput={NoLabelTextField}
              name="GBP"
              value={
                invoiceState.invoiceExchangeRateList.find(
                  (v) => v.currency === "GBP"
                )?.rate || ""
              }
              onValueChange={(value) => {
                handleExchangeRateListChange(value?.floatValue || 0, "GBP");
              }}
              fullWidth
              size="small"
              sx={{
                "& .MuiInputBase-input": {
                  color: checkExchangeModification({
                    currency: "GBP",
                    defaultExchangeRateList,
                    invoiceState,
                  })
                    ? "#2196f3"
                    : "inherit",
                },
              }}
            />
          ),
        },
        {
          label: "환율 기준일",
          labelGridSize: 3,
          gridSize: 2.5,
          value: (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MUIDatePicker
                componentsProps={{
                  actionBar: { actions: ["clear"] },
                }}
                inputFormat="yyyy-MM-dd"
                value={exchangeRateDate}
                onChange={(v) => {
                  if (!v) {
                    // Clear로 초기화 시 초기 셋팅된 날짜로 변경
                    handleResetExchangeInfoClick();
                    return;
                  }
                  setIsUnchangedExchangeRateDate(false);

                  handleExchangeRateDate(
                    transformUTCDateToLocalDateTime({
                      utcDateTime: v,
                      timeZone: "Asia/Seoul",
                      when: "start",
                    })?.toISOString() || toFormattedDateToUTCDate(v)
                  );
                }}
                // 오늘 날짜를 최대 선택 가능 날짜로 설정
                maxDate={new Date()}
                renderInput={(params) => (
                  <NoLabelTextField
                    {...params}
                    onKeyDown={(event) => {
                      event.preventDefault();
                    }}
                    fullWidth={true}
                    autoComplete="off"
                    size="small"
                  />
                )}
              />
            </LocalizationProvider>
          ),
        },
        {
          label: "참고 기준일",
          gridSize: 2.7,
          value: (
            <Box
              display="flex"
              flexDirection={"column"}
              alignItems={"flex-start"}
              sx={{ pt: 3 }}
            >
              <Button
                variant="text"
                sx={{ padding: 0, justifyContent: "flex-start" }}
                onClick={() => {
                  if (!shipmentDetailData.management.fullATD) return;
                  setIsUnchangedExchangeRateDate(false);
                  handleExchangeRateDate(shipmentDetailData.management.fullATD);
                }}
              >
                ATD){" "}
                {toFormattedDate(
                  shipmentDetailData.management.fullATD,
                  "YYYY-MM-DD"
                ) || "-"}
              </Button>

              <Button
                variant="text"
                sx={{
                  padding: 0,
                  justifyContent: "flex-start",
                }}
                onClick={() => {
                  if (!shipmentDetailData.management.fullATA) return;
                  setIsUnchangedExchangeRateDate(false);
                  handleExchangeRateDate(shipmentDetailData.management.fullATA);
                }}
              >
                ATA){" "}
                {toFormattedDate(
                  shipmentDetailData.management.fullATA,
                  "YYYY-MM-DD"
                ) || "-"}
              </Button>
            </Box>
          ),
        },

        {
          label: "",
          gridSize: 2,
          value: (
            <Box display="flex" gap={1} justifyContent={"flex-end"}>
              <Button
                variant="outlined"
                disabled={isResetButtonDisabled}
                onClick={handleResetExchangeInfoClick}
              >
                초기화
              </Button>

              <Button
                variant="contained"
                onClick={handleApplyExchangeInfoClick}
                // 변경이 하나라도 있다면 버튼을 활성화
                disabled={isExchangeInfoUnchanged}
              >
                적용하기
              </Button>
            </Box>
          ),
        },
      ]}
    />
  );
}

export default ExchangeInfo;
