import { useAtomValue } from "jotai";

import ADMIN_PARTNER_MANAGEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_PARTNER_MANAGEMENT_QUERY";
import { useDebounce } from "@sellernote/_shared/src/utils/common/hook";
import { FORWARDING_PARTNER_MANAGEMENT_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/partnerManagement";

export default function useGetPartnerList() {
  const {
    page,
    perPage,
    businessArea,
    transportMode,
    searchType,
    searchValue,
    isDeactivated,
    hasFinancialAccounts,
    isFinancial,
    shipmentTypes,
  } = useAtomValue(FORWARDING_PARTNER_MANAGEMENT_ATOMS.SEARCH_PARAMS);

  const isForeignOrDomesticPartner =
    businessArea === "foreign" || businessArea === "domestic";

  const debouncedSearchValue = useDebounce(searchValue, 1000);

  const { data } = ADMIN_PARTNER_MANAGEMENT_QUERY.useGetPartnerList({
    page,
    perPage,
    businessArea,
    ...(isForeignOrDomesticPartner ? { transportMode } : {}),
    ...(isDeactivated !== undefined ? { isDeactivated } : {}),
    ...(hasFinancialAccounts !== undefined ? { hasFinancialAccounts } : {}),
    ...(isFinancial !== undefined ? { isFinancial } : {}),
    ...(shipmentTypes !== undefined ? { shipmentTypes } : {}),
    [searchType]: debouncedSearchValue,
  });

  return {
    data,
  };
}
