import {
  Box,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";

import { CREATE_PARTNER_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/partnerManagement";

import NO_LABEL from "../styles";

export default function PersonInChargeInfo({
  form,
  onInfoUpdate,
}: {
  form: CREATE_PARTNER_REQ;
  onInfoUpdate: <T>(name: string, value: T | T[]) => void;
}) {
  return (
    <Box display={"flex"} flexDirection={"column"} width={250}>
      <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
        파트너 정보
      </Typography>

      <Box ml={-1}>
        <InputLabel>
          담당자 성함{" "}
          <Typography component={"span"} color="red">
            *
          </Typography>
        </InputLabel>

        <TextField
          required
          value={form.managerName}
          sx={NO_LABEL}
          size="small"
          fullWidth
          onChange={(e) => onInfoUpdate("managerName", e.target.value)}
        />
      </Box>

      <Box ml={-1}>
        <InputLabel>
          메일 주소{" "}
          <Typography component={"span"} color="red">
            *
          </Typography>
        </InputLabel>

        <TextField
          required
          value={form.managerEmail}
          sx={NO_LABEL}
          size="small"
          fullWidth
          onChange={(e) => onInfoUpdate("managerEmail", e.target.value)}
        />
      </Box>

      <Box ml={-1}>
        <InputLabel>직함</InputLabel>

        <TextField
          required
          value={form.managerPosition}
          sx={NO_LABEL}
          size="small"
          fullWidth
          onChange={(e) => onInfoUpdate("managerPosition", e.target.value)}
        />
      </Box>

      <Box ml={-1}>
        <InputLabel>전화번호</InputLabel>

        <TextField
          required
          value={form.managerPhone}
          sx={NO_LABEL}
          size="small"
          fullWidth
          onChange={(e) => onInfoUpdate("managerPhone", e.target.value)}
        />
      </Box>

      <Box ml={-1}>
        <InputLabel>주소</InputLabel>

        <TextField
          required
          multiline
          rows={4}
          value={form.address}
          sx={NO_LABEL}
          size="small"
          fullWidth
          onChange={(e) => onInfoUpdate("address", e.target.value)}
        />

        <FormHelperText>{`${
          form?.address?.length ?? 0
        }/${200}`}</FormHelperText>
      </Box>
    </Box>
  );
}
