import { useCallback, useMemo, useRef, useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  Fade,
  Grid,
  IconButton,
  Paper,
  Popper,
  Typography,
} from "@mui/material";

import {
  AllPossibleServiceType,
  GET_OPERATION_LIST_FILTER,
  OperationServiceType,
  ServiceTypeRequest,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/businessManagement";
import { ADMIN_OPERATION_SERVICE_TYPE_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/adminBusinessManagement";
import { isEmptyObjectOrArray } from "@sellernote/_shared/src/utils/common/etc";
import { FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD } from "@sellernote/_shared-for-forwarding-admin/src/constants";
import useTableHeadFilterUnstyled from "@sellernote/_shared-for-forwarding-admin/src/hooks/useTableHeadFilterUnstyled";

export default function useServiceTypeFilter(
  filterData: GET_OPERATION_LIST_FILTER
) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const serviceTypeHistory = useMemo(() => {
    if (filterData.serviceStartType && filterData.serviceEndType) {
      return {
        serviceStartType: filterData.serviceStartType,
        serviceEndType: filterData.serviceEndType,
      };
    }
  }, [filterData.serviceStartType, filterData.serviceEndType]);

  const getServiceTypeFormChange = useCallback(
    (serviceTypeHistory: ServiceTypeRequest) => {
      if (serviceTypeHistory) {
        const { serviceStartType, serviceEndType } = serviceTypeHistory;

        const mergedServiceTypes =
          serviceStartType &&
          serviceEndType &&
          serviceStartType.reduce(
            (acc: string[], curr: string, index: number) => {
              const combinedRequest = `${curr}-${serviceEndType[index]}`;
              return [...acc, combinedRequest] as AllPossibleServiceType[];
            },
            []
          );

        return mergedServiceTypes;
      }
    },
    []
  );

  const serviceTypeForFilterHistory = useMemo(() => {
    return serviceTypeHistory?.serviceStartType &&
      serviceTypeHistory?.serviceEndType
      ? getServiceTypeFormChange(serviceTypeHistory)
      : null;
  }, [getServiceTypeFormChange, serviceTypeHistory]);

  const [headFilterData, setHeadFilterData] = useState<
    AllPossibleServiceType[] | null
  >(serviceTypeForFilterHistory ?? null);

  const [requestedHeadFilterData, setRequestedHeadFilterData] =
    useState<ServiceTypeRequest | null>(serviceTypeHistory ?? null);

  const [isCanceled, setIsCanceled] = useState<boolean>(true);

  const open = Boolean(anchorEl);

  const id = open ? "simple-popper" : undefined;

  const anchorElRef = useRef<HTMLDivElement>(null);

  const {
    FilterPanel: ServiceTypeFilterPanel,
    draftFilter: ServiceTypeFilterData,
    handleFilterReset: serviceTypeReset,
  } = useTableHeadFilterUnstyled({
    filterOptions: ADMIN_OPERATION_SERVICE_TYPE_OPTION_LIST,
    canSelectAllButton: true,
  });

  const handleSomewhereClick = useCallback(() => {
    setAnchorEl(null);
    setIsCanceled(true);
  }, []);

  const getServiceTypeFilter = useCallback(
    (headFilterData: AllPossibleServiceType[]) => {
      const serviceStartType: OperationServiceType[] = [];
      const serviceEndType: OperationServiceType[] = [];

      if (headFilterData !== undefined) {
        headFilterData?.reduce((_, curr: string) => {
          const [startType, endType] = curr.split("-");
          serviceStartType.push(
            startType.toLowerCase() as OperationServiceType
          );
          serviceEndType.push(endType.toLowerCase() as OperationServiceType);
          return null;
        }, null);
      }

      return { serviceStartType, serviceEndType };
    },
    []
  );

  const handleSubmitFilter = useCallback(() => {
    if (!ServiceTypeFilterData) return setAnchorEl(null);

    if (ServiceTypeFilterData) {
      setRequestedHeadFilterData(getServiceTypeFilter(ServiceTypeFilterData));
      setHeadFilterData(ServiceTypeFilterData);
      setIsCanceled(false);
      setAnchorEl(null);
    }
  }, [getServiceTypeFilter, ServiceTypeFilterData]);

  const handleFilterOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);

      if (isCanceled) {
        if (serviceTypeHistory) {
          serviceTypeReset(serviceTypeForFilterHistory);
        }

        return setIsCanceled(false);
      } else {
        ServiceTypeFilterData && serviceTypeReset(ServiceTypeFilterData);

        return setIsCanceled(false);
      }
    },

    [
      ServiceTypeFilterData,
      serviceTypeHistory,
      serviceTypeForFilterHistory,
      anchorEl,
      serviceTypeReset,
      isCanceled,
    ]
  );

  const filterTriggerPosition = (() => {
    const target = anchorElRef.current;
    if (!target) {
      return null;
    }

    return target.getClientRects()[0];
  })();

  const handleReset = useCallback(() => {
    serviceTypeReset([]);

    setHeadFilterData(null);
    setRequestedHeadFilterData(null);
  }, [serviceTypeReset]);

  const handleInsideReset = useCallback(() => {
    serviceTypeReset([]);
  }, [serviceTypeReset]);

  const ServiceTypeFilter = useMemo(() => {
    const DoubleFilter = () => {
      return (
        <>
          <IconButton
            aria-owns={id}
            aria-describedby={id}
            aria-haspopup={true}
            type="button"
            onClick={handleFilterOpen}
            color={
              !isEmptyObjectOrArray(headFilterData || {})
                ? "primary"
                : "inherit"
            }
          >
            <FilterAltIcon />
          </IconButton>

          <Dialog open={open}>
            <Popper
              key={id}
              placement="bottom-start"
              anchorEl={anchorEl}
              id={id}
              open={open}
              transition
              style={{
                zIndex: FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD.tableHeaderFilter,
              }}
            >
              {({ TransitionProps }) => (
                <ClickAwayListener
                  onClickAway={handleSomewhereClick}
                  disableReactTree
                >
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper
                      sx={{
                        padding: 2,
                        width: "100%",
                        minWidth: "170px",
                        position: "absolute",
                        maxHeight: "300px",
                        overflow: "scroll",
                        top: (filterTriggerPosition?.top ?? 0) + 17,
                        left: (filterTriggerPosition?.left ?? 0) - 40,
                        right: "100%",
                        display: "flex",
                        zIndex:
                          FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD.tableHeaderFilter,
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "white",
                          width: 170,
                          height: 50,
                          position: "fixed",
                          top: 300,
                          left: -40,
                          display: "flex",
                          justifyContent: "space-between",
                          padding: 1,
                        }}
                      >
                        <IconButton onClick={() => handleInsideReset()}>
                          <Typography
                            variant="caption"
                            sx={{ display: "inline-flex" }}
                          >
                            <RestartAltIcon />
                            reset
                          </Typography>
                        </IconButton>

                        <Button variant="outlined" onClick={handleSubmitFilter}>
                          적용
                        </Button>
                      </Box>

                      <Grid>{ServiceTypeFilterPanel}</Grid>
                    </Paper>
                  </Fade>
                </ClickAwayListener>
              )}
            </Popper>
          </Dialog>
        </>
      );
    };

    return <Box>{DoubleFilter()}</Box>;
  }, [
    id,
    open,
    filterTriggerPosition?.left,
    filterTriggerPosition?.top,
    handleFilterOpen,
    anchorEl,
    ServiceTypeFilterPanel,
    handleSomewhereClick,
    headFilterData,
    handleSubmitFilter,
    handleInsideReset,
  ]);

  return {
    ServiceTypeFilter,
    getServiceTypeFormChange,
    getServiceTypeFilter,
    headFilterData,
    requestedHeadFilterData,
    handleReset,
  };
}
