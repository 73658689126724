import { useAtomValue } from "jotai";

import AUTH_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_AUTH_QUERY";
import LoginForAdmin from "@sellernote/_shared-for-forwarding-admin/src/components/LoginForAdmin";
import { FORWARDING_ADMIN_AUTH_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/auth";

export default function Login() {
  const loggedIn = useAtomValue(FORWARDING_ADMIN_AUTH_ATOMS.ADMIN_LOGGED_IN);

  const {
    mutate: login,
    ResponseHandler: ResponseHandlerOfLogin,
    isLoading,
  } = AUTH_QUERY.useAdminLogin();

  return (
    <LoginForAdmin
      login={({ id, password }) => {
        // TODO: 요청 응답에 id가 오면 이 프로세스는 삭제
        window.localStorage.setItem("tempId", id);

        login({ accountId: id, password: password });
      }}
      isLoadingOfLogin={isLoading}
      isLoggedIn={loggedIn}
      ResponseHandlerOfLogin={ResponseHandlerOfLogin}
    />
  );
}
