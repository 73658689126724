import { useCallback } from "react";
import { Box, Button, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import ADMIN_SETTLEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_SETTLEMENT_QUERY";
import {
  isInvalidDate,
  toFormattedDate,
} from "@sellernote/_shared/src/utils/common/date";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

function UpdateInvoiceIssuedDate({
  checkIds,
  initCheckBoxSet,
  refetchAdminSettlementSalesList,
}: {
  checkIds: number[];
  initCheckBoxSet: (values?: number[]) => void;
  refetchAdminSettlementSalesList: () => void;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const { mutate: editInvoiceIssueDate } =
    ADMIN_SETTLEMENT_QUERY.useEditInvoiceIssueDate();

  const handleInvoiceIssueDateEdit = useCallback(
    (date: Date | null, isDateReset: boolean) => {
      if (checkIds.length < 1) {
        return;
      }

      if (!isDateReset && (!date || isInvalidDate(date))) {
        return;
      }

      const checkIdsToStringArr = checkIds.map((v) => {
        return v.toString();
      });

      editInvoiceIssueDate(
        {
          issuedInvoiceIds: checkIdsToStringArr,
          issueDate: !date ? null : toFormattedDate(date, "YYYY-MM-DD"),
        },
        {
          onSuccess: () => {
            initCheckBoxSet();
            handleSnackbarOpen("요청에 성공했습니다.");
            refetchAdminSettlementSalesList();
          },

          onError: () => {
            handleSnackbarOpen("요청에 실패했습니다.", "error");
          },
        }
      );
    },
    [
      checkIds,
      editInvoiceIssueDate,
      handleSnackbarOpen,
      initCheckBoxSet,
      refetchAdminSettlementSalesList,
    ]
  );

  return (
    <Box sx={{ display: "flex", gap: "4px", alignSelf: "flex-start" }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          disabled={checkIds.length < 1}
          value={null}
          componentsProps={{
            actionBar: { actions: ["clear"] },
          }}
          inputFormat="yyyy-MM-dd"
          label="계산서 발행일 입력"
          renderInput={(params) => (
            <TextField
              onKeyDown={(event) => {
                event.preventDefault();
              }}
              sx={{ width: 200 }}
              size="small"
              {...params}
            />
          )}
          onChange={(date) => handleInvoiceIssueDateEdit(date, false)}
        />
      </LocalizationProvider>

      {checkIds.length >= 1 && (
        <Button
          variant="contained"
          color="error"
          disabled={checkIds.length < 1}
          onClick={() => handleInvoiceIssueDateEdit(null, true)}
        >
          발행일 초기화
        </Button>
      )}
    </Box>
  );
}

export default UpdateInvoiceIssuedDate;
