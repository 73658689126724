import { useCallback, useMemo } from "react";
import { Box } from "@mui/material";

import {
  GET_ADMIN_SETTLEMENT_SALES_LIST_REQ_DATE_KIND,
  GET_ADMIN_SETTLEMENT_SALES_LIST_REQ_SEARCH_KIND,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminSettlement";
import { ADMIN_FORWARDING_MANAGER_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/adminAuth";
import { BooleanStringV2 } from "@sellernote/_shared/src/types/common/common";
import { SettlementSalesListStatus } from "@sellernote/_shared/src/types/forwarding/adminSettlement";
import { InvoiceType } from "@sellernote/_shared/src/types/forwarding/trello";
import useSearchWithDate, {
  SearchWithDateTypeOption,
} from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSearchWithDate";
import useSearchWithTerm from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSearchWithTerm";
import { TermSearchType } from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSearchWithTermWithHistorySave";
import useSelectFilter, {
  SelectFilterOptionList,
} from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSelectFilter";

const dateSearchTypeOptionList: SearchWithDateTypeOption<GET_ADMIN_SETTLEMENT_SALES_LIST_REQ_DATE_KIND>[] =
  [
    {
      label: "입금일 기준",
      value: "depositDate",
    },
    {
      label: "발행일 기준",
      value: "createdAt",
    },
    {
      label: "계산서 발행일",
      value: "issueDate",
    },
  ];

const termSearchTypeOptionList: TermSearchType<GET_ADMIN_SETTLEMENT_SALES_LIST_REQ_SEARCH_KIND>[] =
  [
    {
      label: "회사명",
      value: "recipient",
    },
    {
      label: "의뢰번호",
      value: "bidId",
    },
    {
      label: "사업자등록번호",
      value: "BRN",
    },
    {
      label: "BL",
      value: "BL",
    },
  ];

const invoiceTypeSelectFilterOptionList: SelectFilterOptionList<
  undefined | InvoiceType
>[] = [
  {
    label: "전체",
    value: undefined,
  },
  {
    label: "거래명세서",
    value: "invoice",
  },
  {
    label: "부킹 커미션",
    value: "refund",
  },
  {
    label: "창고료",
    value: "warehouseReceipt",
  },
  {
    label: "기타입금",
    value: "etcDeposit",
  },
];

const booleanSelectFilterOptionList: SelectFilterOptionList<
  undefined | BooleanStringV2
>[] = [
  {
    label: "전체",
    value: undefined,
  },
  {
    label: "O",
    value: "TRUE",
  },
  {
    label: "X",
    value: "FALSE",
  },
];

type NonNullSettlementSalesListStatus =
  | Exclude<SettlementSalesListStatus, null>
  | "null"
  | "none";

const invoiceStatusSelectFilterOptionList: SelectFilterOptionList<NonNullSettlementSalesListStatus>[] =
  [
    {
      label: "전체",
      value: "null",
    },
    {
      label: "- (발행 전)",
      value: "none",
    },
    {
      label: "발행취소",
      value: "canceled",
    },
    {
      label: "발행 실패",
      value: "rejected",
    },
    {
      label: "전송중",
      value: "sending",
    },
    {
      label: "전송성공",
      value: "done",
    },
    {
      label: "전송실패",
      value: "failed",
    },
  ];

export default function useSalesSettlementTableFilter() {
  const { DateSearchPanel, dateSearchType, startDate, endDate } =
    useSearchWithDate({
      dateSearchTypeOptions: dateSearchTypeOptionList,
    });

  const { TermSearchPanel, debouncedSearchTermWithObject } = useSearchWithTerm({
    termSearchTypeOptions: termSearchTypeOptionList,
  });

  const {
    SelectFilterPanel: ForwardingManagerSelectFilterPanel,
    selectFilter: forwardingManagerSelectFilter,
  } = useSelectFilter({
    selectFilterOptionList: [
      { label: "전체", value: undefined },
      ...ADMIN_FORWARDING_MANAGER_OPTION_LIST,
    ],
    label: "담당자",
  });

  const {
    SelectFilterPanel: InvoiceTypeSelectFilterOptionList,
    selectFilter: invoiceTypeSelectFilter,
  } = useSelectFilter({
    selectFilterOptionList: invoiceTypeSelectFilterOptionList,
    label: "구분",
  });

  const {
    SelectFilterPanel: HasDepositSelectFilterPanel,
    selectFilter: hasDepositSelectFilter,
  } = useSelectFilter({
    selectFilterOptionList: booleanSelectFilterOptionList,
    label: "입금일 유무",
  });

  const {
    SelectFilterPanel: IsIssuedSelectFilterPanel,
    selectFilter: isIssuedSelectFilter,
  } = useSelectFilter({
    selectFilterOptionList: booleanSelectFilterOptionList,
    label: "계산서 발행",
  });

  const {
    SelectFilterPanel: HasRequestIssueDateSelectFilterPanel,
    selectFilter: hasRequestIssueDateSelectFilter,
  } = useSelectFilter({
    selectFilterOptionList: booleanSelectFilterOptionList,
    label: "고객 요청일 유무",
  });

  const {
    SelectFilterPanel: InvoiceStatusSelectFilterOptionList,
    selectFilter: invoiceStatusSelectFilter,
  } = useSelectFilter({
    selectFilterOptionList: invoiceStatusSelectFilterOptionList,
    label: "상태",
  });

  const changeBooleanStringToBoolean = useCallback(
    (
      booleanString:
        | SelectFilterOptionList<BooleanStringV2 | undefined>
        | undefined
    ) => {
      if (booleanString?.value === "TRUE") {
        return true;
      }

      if (booleanString?.value === "FALSE") {
        return false;
      }

      return undefined;
    },
    []
  );

  const SalesSettlementTableFilter = useMemo(() => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          {ForwardingManagerSelectFilterPanel}
          {TermSearchPanel}
          {DateSearchPanel}
        </Box>

        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          {InvoiceTypeSelectFilterOptionList}
          {HasDepositSelectFilterPanel}
          {IsIssuedSelectFilterPanel}
          {HasRequestIssueDateSelectFilterPanel}
          {InvoiceStatusSelectFilterOptionList}
        </Box>
      </Box>
    );
  }, [
    ForwardingManagerSelectFilterPanel,
    TermSearchPanel,
    DateSearchPanel,
    InvoiceTypeSelectFilterOptionList,
    HasDepositSelectFilterPanel,
    IsIssuedSelectFilterPanel,
    HasRequestIssueDateSelectFilterPanel,
    InvoiceStatusSelectFilterOptionList,
  ]);

  return {
    SalesSettlementTableFilter,
    invoiceTypeSelectFilterValue: invoiceTypeSelectFilter?.value,
    invoiceStatusSelectFilterValue: invoiceStatusSelectFilter?.value,
    hasDepositSelectFilterValue: changeBooleanStringToBoolean(
      hasDepositSelectFilter
    ),
    isIssuedSelectFilterValue:
      changeBooleanStringToBoolean(isIssuedSelectFilter),
    dateSearchType,
    startDate,
    endDate,
    debouncedSearchTermWithObject,
    forwardingManagerSelectFilterValue: forwardingManagerSelectFilter?.value,
    hasRequestIssueDateSelectFilterValue: changeBooleanStringToBoolean(
      hasRequestIssueDateSelectFilter
    ),
  };
}
