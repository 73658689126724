import { Dispatch, SetStateAction, useCallback, useState } from "react";
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";

import ADMIN_SETTLEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_SETTLEMENT_QUERY";
import { Currency } from "@sellernote/_shared/src/types/common/common";
import {
  DepositV2ListItem,
  SettlementFinancialDepositListData,
  SettlementFinancialWithdrawListData,
} from "@sellernote/_shared/src/types/forwarding/adminSettlement";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";
import TextField from "@sellernote/_shared-for-admin/src/components/TextField";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

import useSettlementTableMemoData from "./useSettlementTableMemoData";

function TableMemoModal({
  showsTableMemoModal,
  setShowsTableMemoModal,
  paymentInvoiceId,
  refetchListData,
  amount,
  name,
  settlementDepositData,
  settlementWithdrawalData,
  settlementDepositV2Data,
  type,
  currency,
}: {
  showsTableMemoModal: boolean;
  setShowsTableMemoModal: Dispatch<SetStateAction<boolean>>;
  paymentInvoiceId: number;
  refetchListData: () => void;
  amount: number;
  name: string;
  settlementDepositData?: SettlementFinancialDepositListData[];
  settlementWithdrawalData?: SettlementFinancialWithdrawListData[];
  settlementDepositV2Data?: DepositV2ListItem[];
  // TODO: 입금관리가 끝나면 depositV2를 deposit으로 변경
  type: "deposit" | "withdrawal" | "depositV2";
  currency: Currency;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const [memo, setMemo] = useState("");

  const { mutate: registerFinancialMemo } =
    ADMIN_SETTLEMENT_QUERY.useRegisterFinancialMemo();

  const { memoData } = useSettlementTableMemoData({
    settlementDepositData,
    settlementWithdrawalData,
    settlementDepositV2Data,
    paymentInvoiceId,
    type,
  });

  const handleMemoRegister = useCallback(() => {
    registerFinancialMemo(
      {
        paymentInvoiceId,
        body: memo,
      },
      {
        onSuccess: () => {
          handleSnackbarOpen("메모가 등록되었습니다.");
          setMemo("");
          refetchListData();
        },

        onError: () => {
          handleSnackbarOpen("메모 등록에 실패했습니다.", "error");
        },
      }
    );
  }, [
    handleSnackbarOpen,
    memo,
    paymentInvoiceId,
    refetchListData,
    registerFinancialMemo,
  ]);

  const handleModalClose = () => {
    setShowsTableMemoModal(false);
    refetchListData();
  };

  return (
    <Modal
      isOpened={showsTableMemoModal}
      handleClose={handleModalClose}
      modalBody={
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ color: blue[700], textAlign: "center" }}
            >
              메모
            </Typography>
          </Grid>

          <Grid container item justifyContent={"flex-start"}>
            <Grid item>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>입금자명</TableCell>

                      <TableCell>{name}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>입금액</TableCell>

                      <TableCell>{`${currency} ${toThousandUnitFormat(
                        amount
                      )}`}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          <Grid item>
            <TextField
              placeholder="메모를 남겨주세요"
              sx={{ width: 400 }}
              multiline={true}
              size="small"
              onChange={(e) => setMemo(e.target.value)}
            />

            <Button onClick={handleMemoRegister} variant="contained">
              등록
            </Button>
          </Grid>

          <Grid item>
            <List>
              {memoData.map((n, index) => {
                return (
                  <ListItem key={index} disablePadding>
                    <ListItemIcon>{n.id}</ListItemIcon>

                    <ListItemText
                      sx={{ marginLeft: "16px" }}
                      primary={n.comment}
                      secondary={n.date}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
      }
    />
  );
}

export default TableMemoModal;
