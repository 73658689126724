import { useCallback, useEffect, useRef } from "react";
import { CSVLink } from "react-csv";
import { Button, Grid } from "@mui/material";

import { GET_ADMIN_SETTLEMENT_SALES_LIST_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminSettlement";
import ADMIN_SETTLEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_SETTLEMENT_QUERY";

function SalesListExcelDownload({
  fetchListParams,
}: {
  fetchListParams: GET_ADMIN_SETTLEMENT_SALES_LIST_REQ;
}) {
  const { salesExcelList, refetch, remove } =
    ADMIN_SETTLEMENT_QUERY.useGetAdminSettlementSalesExcelList(fetchListParams);

  const csvLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  useEffect(() => {
    if (salesExcelList) {
      csvLink?.current?.link.click();
      remove();
    }
  }, [remove, salesExcelList]);

  const handleSalesExcelListReFetch = useCallback(() => {
    refetch();
  }, [refetch]);

  return (
    <Grid container justifyContent="flex-end">
      <Grid item>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSalesExcelListReFetch}
        >
          세금계산서 용
        </Button>

        {salesExcelList && (
          <CSVLink
            ref={csvLink}
            headers={[
              { label: "전자(세금)계산서 종류", key: "issueInvoiceType" },
              { label: "작성일자", key: "depositDate" },
              { label: "공급자 등록번호", key: "sellerNoteRegistrationNumber" },
              { label: "공급자 종사업장번호", key: "" },
              { label: "공급자 상호", key: "sellerNoteName" },
              { label: "공급자 성명", key: "sellerNoteRepresentative" },
              { label: "공급자 사업장 주소", key: "sellerNoteAddress" },
              { label: "공급자 업태", key: "sellerNoteBusiness" },
              { label: "공급자 종목", key: "sellerNoteBusinessType" },
              { label: "공급자 이메일", key: "sellerNoteTaxEmail" },
              { label: "공급받는자 등록번호", key: "BRNNumber" },
              { label: "공급받는자 종사업장번호", key: "" },
              { label: "공급받는자 상호", key: "companyName" },
              { label: "공급받는자 성명", key: "" },
              { label: "공급받는자 사업장주", key: "" },
              { label: "공급받는자 업태", key: "" },
              { label: "공급받는자 종목", key: "" },
              { label: "공급받는자 이메일1", key: "companyEmail" },
              { label: "공급받는자 이메일2", key: "" },
              { label: "공급가액", key: "totalPrice" },
              { label: "세액", key: "vatPrice" },
              { label: "비고", key: "remark" },
              { label: "일자1", key: "depositDateDetail" },
              { label: "품목1", key: "subject1" },
              { label: "규격1", key: "" },
              { label: "수량1", key: "" },
              { label: "단가1", key: "" },
              { label: "공급가액1", key: "totalPrice" },
              { label: "세액1", key: "vatPrice" },
              { label: "품목비고1", key: "subjectRemark1" },
              { label: "일자2", key: "" },
              { label: "품목2", key: "" },
              { label: "규격2", key: "" },
              { label: "수량2", key: "" },
              { label: "단가2", key: "" },
              { label: "공급가액2", key: "" },
              { label: "세액2", key: "" },
              { label: "품목비고2", key: "" },
              { label: "일자3", key: "" },
              { label: "품목3", key: "" },
              { label: "규격3", key: "" },
              { label: "수량3", key: "" },
              { label: "단가3", key: "" },
              { label: "공급가액3", key: "" },
              { label: "세액3", key: "" },
              { label: "품목비고3", key: "" },
              { label: "일자4", key: "" },
              { label: "품목4", key: "" },
              { label: "규격4", key: "" },
              { label: "수량4", key: "" },
              { label: "단가4", key: "" },
              { label: "공급가액4", key: "" },
              { label: "세액4", key: "" },
              { label: "품목비고4", key: "" },
              { label: "현금", key: "" },
              { label: "수표", key: "" },
              { label: "어음", key: "" },
              { label: "외상미수금", key: "" },
              { label: "영수(01), 청구(02)", key: "receipt" },
            ]}
            data={salesExcelList}
            filename="매출관리리스트.csv"
            target="_blank"
          />
        )}
      </Grid>
    </Grid>
  );
}

export default SalesListExcelDownload;
