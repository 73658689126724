import { useCallback, useEffect, useRef } from "react";
import { CSVLink } from "react-csv";
import { Button, Grid } from "@mui/material";

import { GET_ADMIN_SETTLEMENT_SALES_LIST_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminSettlement";
import ADMIN_SETTLEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_SETTLEMENT_QUERY";

function SalesChitExcelDownload({
  fetchListParams,
}: {
  fetchListParams: GET_ADMIN_SETTLEMENT_SALES_LIST_REQ;
}) {
  const {
    data: salesChitExcelData,
    refetch,
    remove,
  } = ADMIN_SETTLEMENT_QUERY.useGetAdminSettlementSalesChitExcelList(
    fetchListParams
  );

  const csvLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  //엑셀 데이터가 있으면 useRef로 연결된 csvLink 클릭을 해준다.
  useEffect(() => {
    if (salesChitExcelData) {
      csvLink?.current?.link.click();
      remove();
    }
  }, [remove, salesChitExcelData]);

  const handleSalesExcelListReFetch = useCallback(() => {
    refetch();
  }, [refetch]);

  return (
    <Grid container justifyContent="flex-end">
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSalesExcelListReFetch}
        >
          전표 용
        </Button>

        {salesChitExcelData && (
          <CSVLink
            ref={csvLink}
            headers={[
              { label: "bidId", key: "bidId" },
              { label: "mbl", key: "mbl" },
              { label: "hbl", key: "hbl" },
              { label: "invoiceType", key: "invoiceType" },
              { label: "recipient", key: "recipient" },
              { label: "totalPrice", key: "totalPrice" },
              { label: "영세", key: "zeroTaxFee" },
              { label: "과세", key: "taxFee" },
              { label: "관세", key: "customsFee" },
              { label: "부가가치세", key: "vatFee" },
              { label: "쿠폰", key: "couponFee" },
              { label: "vatPrice", key: "vatPrice" },
              { label: "세금소개", key: "taxSubTotal" },
              { label: "finalPrice", key: "finalPrice" },
              { label: "depositDate", key: "depositDate" },
              { label: "담당자", key: "name" },
            ]}
            data={salesChitExcelData}
            filename="전표 데이터.csv"
            target="_blank"
          />
        )}
      </Grid>
    </Grid>
  );
}

export default SalesChitExcelDownload;
