import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { MenuItem, Select } from "@mui/material";
import { Button, DatePicker, Modal } from "antd";
import moment from "moment";

import BUSINESS_MANAGEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/BUSINESS_MANAGEMENT_QUERY";

const ExcelDownloadModal = ({
  setShowExcelDownloadModal,
  showExcelDownloadModal,
}: {
  setShowExcelDownloadModal: React.Dispatch<React.SetStateAction<boolean>>;
  showExcelDownloadModal: boolean;
}) => {
  const [fetchParams, setFetchParams] = useState<{
    createdAtFromDate: string | undefined;
    createdAtToDate: string | undefined;
    remarketingFromDate: string | undefined;
    remarketingToDate: string | undefined;
  }>({
    createdAtFromDate: undefined,
    createdAtToDate: undefined,
    remarketingFromDate: undefined,
    remarketingToDate: undefined,
  });
  const csvLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const [dateType, setDateType] = useState("create");
  const [isAutoFetch, setIsAutoFetch] = useState(false);

  const {
    data: excelData,
    newExcelList,
    isLoading,
  } = BUSINESS_MANAGEMENT_QUERY.useGetBusinessManagementExcelList(
    {
      createdAtFromDate: fetchParams.createdAtFromDate,
      createdAtToDate: fetchParams.createdAtToDate,
      remarketingFromDate: fetchParams.remarketingFromDate,
      remarketingToDate: fetchParams.remarketingToDate,
    },
    isAutoFetch
  );

  useEffect(() => {
    if (excelData) {
      csvLink?.current?.link.click();
      setIsAutoFetch(false);
      setFetchParams({
        createdAtFromDate: undefined,
        createdAtToDate: undefined,
        remarketingFromDate: undefined,
        remarketingToDate: undefined,
      });
    } else {
      return;
    }
  }, [excelData]);

  const handleDateType = (dateType: unknown) => {
    if (typeof dateType === "string") {
      setDateType(dateType);
      setIsAutoFetch(false);
      setFetchParams({
        createdAtFromDate: undefined,
        createdAtToDate: undefined,
        remarketingFromDate: undefined,
        remarketingToDate: undefined,
      });
    }
  };

  const startDateValue = () => {
    if (dateType === "remarketing") {
      return fetchParams.remarketingFromDate;
    } else {
      return fetchParams.createdAtFromDate;
    }
  };

  const endDateValue = () => {
    if (dateType === "remarketing") {
      return fetchParams.remarketingToDate;
    } else {
      return fetchParams.createdAtToDate;
    }
  };

  const handleChangeFromDate = (date: string) => {
    if (dateType === "remarketing") {
      setFetchParams({
        ...fetchParams,
        remarketingFromDate: date === "" ? undefined : date,
      });
    } else {
      setFetchParams({
        ...fetchParams,
        createdAtFromDate: date === "" ? undefined : date,
      });
    }
  };

  const handleChangeToDate = (date: string) => {
    if (dateType === "remarketing") {
      setFetchParams({
        ...fetchParams,
        remarketingToDate: date === "" ? undefined : date,
      });
    } else {
      setFetchParams({
        ...fetchParams,
        createdAtToDate: date === "" ? undefined : date,
      });
    }
  };

  return (
    <Modal
      destroyOnClose
      width={680}
      bodyStyle={{ height: 240, padding: "24px 24px" }}
      visible={showExcelDownloadModal}
      onCancel={() => {
        setIsAutoFetch(false);
        setShowExcelDownloadModal(false);
      }}
      footer={null}
    >
      <div
        style={{
          fontWeight: "bold",
          fontSize: 16,
          marginBottom: 24,
          color: "#1890FF",
        }}
      >
        DATA
      </div>

      <div
        style={{
          padding: "16px 16px",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <Select
            value={dateType}
            size="small"
            variant="outlined"
            onChange={(e) => handleDateType(e.target.value)}
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
              width: 150,
              mr: 2,
            }}
          >
            <MenuItem value="create">{"견적 생성일"}</MenuItem>
            <MenuItem value="remarketing">{"영업일"}</MenuItem>
          </Select>

          <DatePicker
            placeholder="날짜선택"
            style={{ width: "215px", height: 40 }}
            value={
              startDateValue() === undefined
                ? undefined
                : moment(`${startDateValue()}`, "YYYY/MM/DD")
            }
            onChange={(date, dateString) => handleChangeFromDate(dateString)}
          ></DatePicker>

          <div
            style={{
              marginTop: "5px",
              marginLeft: 8,
              marginRight: 8,
            }}
          >
            ~
          </div>

          <DatePicker
            placeholder="날짜선택"
            style={{ width: "215px", height: 40 }}
            value={
              endDateValue() === undefined
                ? undefined
                : moment(`${endDateValue()}`, "YYYY/MM/DD")
            }
            onChange={(date, dateString) => handleChangeToDate(dateString)}
          ></DatePicker>
        </div>
        <div style={{ textAlign: "center", marginTop: 16 }}>
          <Button
            loading={isLoading}
            disabled={
              fetchParams.remarketingFromDate === undefined &&
              fetchParams.remarketingToDate === undefined &&
              fetchParams.createdAtFromDate === undefined &&
              fetchParams.createdAtToDate === undefined
            }
            type="primary"
            style={{ borderRadius: 2 }}
            onClick={(e) => {
              setIsAutoFetch(true);
            }}
          >
            엑셀 다운로드
          </Button>
          {newExcelList && (
            <CSVLink
              headers={[
                { label: "담당자", key: "managerName" },
                { label: "의뢰번호", key: "bidId" },
                { label: "의뢰 생성일", key: "createdAt" },
                { label: "현재 상태", key: "status" },
                { label: "회사명", key: "companyName" },
                { label: "운송유형", key: "serviceType" },
                { label: "INCOTERMS", key: "incoterms" },
                { label: "운송수단", key: "freightType" },
                { label: "물품상세", key: "productsInfo" },
                { label: "출발항", key: "startPort" },
                { label: "도착항", key: "endPort" },
                { label: "컨택횟수", key: "calls" },
                { label: "영업일시", key: "contactedAt" },
                { label: "미수주사유", key: "noProceedReason" },
                { label: "재영업일자", key: "remarketingDate" },
                { label: "진행여부", key: "inProgressFlag" },
                { label: "자동견적 여부", key: "autoEstimateFlag" },
                { label: "견적 생성일", key: "quotationCreatedAt" },
                { label: "취소 여부", key: "isExporterPartner" },
                { label: "수출자 정보입력", key: "initialProgressDate" },
              ]}
              ref={csvLink}
              data={newExcelList}
              filename="영업관리 리스트.csv"
              target="_blank"
            ></CSVLink>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ExcelDownloadModal;
