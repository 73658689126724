import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Box, Button } from "@mui/material";

import { UPDATE_USER_DETAIL_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBidUser";
import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import ADMIN_BID_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_BID_USER_QUERY";
import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_COMMON_QUERY";
import ADMIN_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_USER_QUERY";
import CommentList from "@sellernote/_shared-for-forwarding-admin/src/components/CommentList";
import Tabs from "@sellernote/_shared-for-forwarding-admin/src/components/Tabs";
import BasicInfo from "@sellernote/_shared-for-forwarding-admin/src/components/TeamAndUserDetail/BasicInfo";
import BidCreate from "@sellernote/_shared-for-forwarding-admin/src/components/TeamAndUserDetail/BidCreate";
import EtcInfo from "@sellernote/_shared-for-forwarding-admin/src/components/TeamAndUserDetail/EtcInfo";
import POInfo from "@sellernote/_shared-for-forwarding-admin/src/components/TeamAndUserDetail/POInfo";
import SalesInfo from "@sellernote/_shared-for-forwarding-admin/src/components/TeamAndUserDetail/SalesInfo";
import SearchLogTable from "@sellernote/_shared-for-forwarding-admin/src/components/TeamAndUserDetail/SearchLogTable";
import SettlementInfo from "@sellernote/_shared-for-forwarding-admin/src/components/TeamAndUserDetail/SettlementInfo";
import ShipmentTable from "@sellernote/_shared-for-forwarding-admin/src/components/TeamAndUserDetail/ShipmentTable";
import TeamInfo from "@sellernote/_shared-for-forwarding-admin/src/components/TeamAndUserDetail/TeamInfo";
import TeamList from "@sellernote/_shared-for-forwarding-admin/src/components/TeamAndUserDetail/TeamList";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";
import useUserAndTeamCommentList from "@sellernote/_shared-for-forwarding-admin/src/hooks/useUserAndTeamCommentList";

function UserDetail() {
  const { handleSnackbarOpen } = useSnackbar();

  const { id }: { id: string } = useParams();

  const { control, watch, reset, formState } =
    useForm<UPDATE_USER_DETAIL_REQ>();

  const [isEditMode, setIsEditMode] = useState(false);

  const { data: userDetail, refetch: refetchUserDetail } =
    ADMIN_BID_USER_QUERY.useGetAdminNewBidUserDetail(Number(id));

  const { data: portList } = ADMIN_COMMON_QUERY.useGetPortList();

  const { data: adminList } = ADMIN_USER_QUERY.useGetAdminUserList();

  const { data: fullfillmentManagerData } =
    ADMIN_BID_USER_QUERY.useGetFullfillmentManagers({});

  const { handleCommentUpdate, comment, handleCommentInputChange } =
    useUserAndTeamCommentList({
      userOrTeamId: Number(id),
      pageType: "user",
    });

  const {
    mutate: updateTeamDetail,
    ResponseHandler: ResponseHandlerOfUpdateTeamDetail,
  } = ADMIN_BID_USER_QUERY.useUpdateUserDetail({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        refetchUserDetail();
        reset();
        setIsEditMode(false);
      },

      customizeMessage: () => ({
        title: "유저 상세를 수정했습니다.",
      }),
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "유저 상세 수정에서 오류가 발생했습니다.",
      }),
    },
  });

  const handleTeamDetailUpdate = () => {
    if (
      Boolean(userDetail?.attachmentBRN?.length) &&
      watch().BRN?.length !== 10
    ) {
      handleSnackbarOpen("사업자 등록번호 10자리를 입력해주세요.", "warning");
      return;
    }

    updateTeamDetail({
      ...watch(),
      email: watch("email") !== userDetail?.email ? watch("email") : undefined,
      id: Number(id),
    });
  };

  const handleTeamDetailUpdateCancel = () => {
    reset();
    setIsEditMode(false);
  };

  const handleEditModeChange = () => {
    setIsEditMode(true);
  };

  if (!userDetail || !adminList || !portList || !fullfillmentManagerData) {
    return (
      <Layout>
        <Loading active={true} />
      </Layout>
    );
  }

  return (
    <Layout title={"사용자 상세"}>
      <Box sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}>
        {!isEditMode && (
          <Button variant="outlined" onClick={handleEditModeChange}>
            수정
          </Button>
        )}

        {isEditMode && (
          <Box>
            <Button variant="contained" onClick={handleTeamDetailUpdate}>
              확인
            </Button>

            <Button
              variant="contained"
              color="error"
              onClick={handleTeamDetailUpdateCancel}
            >
              취소
            </Button>
          </Box>
        )}

        <BidCreate
          userId={userDetail.userId}
          joinedAllTeam={userDetail.joinedAllTeam}
          userName={userDetail.userName}
          teamId={userDetail.teamId}
          corpSizeType={userDetail.corpSizeType}
          isUserCreate={true}
          teamName={userDetail.teamName}
          teamMemberList={userDetail.members}
        />
      </Box>

      <BasicInfo
        detailData={userDetail}
        adminList={adminList}
        type={"user"}
        fullfillmentManagerData={fullfillmentManagerData}
        refetchDetailData={refetchUserDetail}
        isEditMode={isEditMode}
        control={control}
      />

      <SettlementInfo
        detailData={userDetail}
        control={control}
        isEditMode={isEditMode}
        type={"user"}
      />

      <EtcInfo
        type={"user"}
        detailData={userDetail}
        control={control}
        isEditMode={isEditMode}
      />

      <SalesInfo type={"user"} detailData={userDetail} />

      <Tabs
        tabsData={[
          {
            tabLabel: "의뢰현황",
            tabPanelItem: (
              <ShipmentTable detailData={userDetail} portList={portList} />
            ),
          },
          {
            tabLabel: "검색기록",
            tabPanelItem: (
              <SearchLogTable detailData={userDetail} portList={portList} />
            ),
          },
          {
            tabLabel: "발주현황",
            tabPanelItem: <POInfo detailData={userDetail} type={"user"} />,
          },
          {
            tabLabel: "메모",
            tabPanelItem: (
              <CommentList
                commentPageType="user"
                comment={comment}
                commentDataList={userDetail.comments}
                onCommentInputChange={handleCommentInputChange}
                onCommentUpdate={handleCommentUpdate}
              />
            ),
          },
        ]}
      />

      <TeamInfo detailData={userDetail} />

      <TeamList detailData={userDetail} />

      {ResponseHandlerOfUpdateTeamDetail}
    </Layout>
  );
}

export default withRequireAuth(UserDetail);
