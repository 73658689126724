import { useHistory, useParams } from "react-router-dom";
import { Button, DatePicker, Form, Input, InputNumber, Select } from "antd";
import moment from "moment";

import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import ADMIN_COUPON_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_COUPON_QUERY";
import { sendRequest } from "@sellernote/_shared/src/services/request";
import { AdminCouponDetail } from "@sellernote/_shared/src/types/forwarding/coupon";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

//TODO: antd form에서 react-hook-form으로 변경

const CouponDetail = () => {
  const { handleSnackbarOpen } = useSnackbar();

  const history = useHistory();

  const { id }: { id: string } = useParams();

  const { data: couponData } = ADMIN_COUPON_QUERY.useGetAdminCouponDetail({
    couponId: Number(id),
  });

  const initialValuetype = () => {
    if (!couponData) {
      return {};
    }
    const initialValue = {
      campaignName: couponData.campaignName,
      comment: couponData.comment,
      status: couponData.status,
      kind: couponData.kind,
      couponValidDays: couponData.couponValidDays,
      discountAmount: couponData.discountAmount,
      startDate: moment(`${couponData.startDate}`, "YYYY/MM/DD"),
      endDate: moment(`${couponData.endDate}`, "YYYY/MM/DD"),
    };
    return initialValue;
  };

  const onFinish = async (values: AdminCouponDetail) => {
    try {
      await sendRequest({
        method: "put",
        path: `/coupon/campaign/${couponData?.id}`,
        apiType: "ShipdaAdminDefaultNew",
        data: {
          campaignName: values.campaignName,
          status: values.status,
          discountAmount: values.discountAmount,
          startDate: values.startDate,
          endDate: values.endDate,
          couponValidDays: values.couponValidDays,
          comment: values.comment,
        },
      });
      handleSnackbarOpen("쿠폰을 수정했습니다.");
      history.go(-1);
    } catch (e) {
      handleSnackbarOpen("쿠폰 수정에 실패했습니다.", "error");
    }
  };

  if (!couponData) {
    return (
      <Layout breadcrumbs={["쿠폰"]} title={"쿠폰 상세"}>
        <Loading active={true} />
      </Layout>
    );
  }

  return (
    <Layout breadcrumbs={["쿠폰"]} title={"쿠폰 상세"}>
      <div style={{ marginLeft: 24 }}>
        <Form
          layout="vertical"
          onFinish={onFinish}
          initialValues={initialValuetype()}
        >
          <Form.Item
            rules={[
              {
                required: true,
                message: "빈 칸을 채워주세요",
              },
            ]}
            label="쿠폰명"
            name="campaignName"
          >
            <Input style={{ width: 400 }}></Input>
          </Form.Item>
          <Form.Item label="쿠폰 설명" name="comment">
            <Input style={{ width: 200 }}></Input>
          </Form.Item>
          <Form.Item label="쿠폰 형식" name="kind">
            <Select disabled style={{ width: 200 }}>
              <Select.Option value="oneTime">일회용 쿠폰</Select.Option>
              <Select.Option value="fixed">일반 쿠폰</Select.Option>
              <Select.Option value="programatic">자동발급 쿠폰</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "빈 칸을 채워주세요",
              },
            ]}
            label="status"
            name="status"
          >
            <Select style={{ width: 200 }}>
              <Select.Option value="normal">정상</Select.Option>
              <Select.Option value="expired">만료</Select.Option>
              <Select.Option value="terminated">종료</Select.Option>
              <Select.Option value="notWorking">정지</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "빈 칸을 채워주세요",
              },
            ]}
            label="할인 금액"
            name="discountAmount"
          >
            <InputNumber style={{ width: 200 }}></InputNumber>
          </Form.Item>
          <div style={{ display: "flex" }}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "빈 칸을 채워주세요",
                },
              ]}
              style={{ marginRight: 16 }}
              label="캠페인 시작일"
              name="startDate"
            >
              <DatePicker style={{ width: 200 }}></DatePicker>
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: true,
                  message: "빈 칸을 채워주세요",
                },
              ]}
              label="캠페인 종료일"
              name="endDate"
            >
              <DatePicker style={{ width: 200 }}></DatePicker>
            </Form.Item>
          </div>
          <Form.Item
            rules={[
              {
                required: true,
                message: "빈 칸을 채워주세요",
              },
            ]}
            label="쿠폰 유효기간"
            name="couponValidDays"
          >
            <InputNumber style={{ width: 200 }}></InputNumber>
          </Form.Item>
          <Form.Item>
            <Button
              style={{
                backgroundColor: "#1890FF",
                width: 154,
                height: 32,
                padding: 0,
                color: "#fff",
              }}
              htmlType="submit"
            >
              쿠폰 수정
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Layout>
  );
};

export default withRequireAuth(CouponDetail);
