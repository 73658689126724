import { useHistory } from "react-router-dom";
import { Box, Button, Divider, Typography } from "@mui/material";

import { GET_BUSINESS_MANAGEMENT_FILTER_LIST } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/businessManagement";
import BUSINESS_MANAGEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/BUSINESS_MANAGEMENT_QUERY";
import { THIS_YEAR_AS_TWO_DIGITS } from "@sellernote/_shared/src/utils/common/date";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import useSelectFilter from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSelectFilter";

function ManagerSummary({
  forwardingManagerAdminId,
  businessManagementFilter,
  onForwardingManagerFilterHistoryChange,
  forwardingManagerAdminList,
}: {
  forwardingManagerAdminId: number;
  businessManagementFilter: GET_BUSINESS_MANAGEMENT_FILTER_LIST;
  onForwardingManagerFilterHistoryChange: (value: number) => void;
  forwardingManagerAdminList: {
    label: string;
    value: number;
  }[];
}) {
  const history = useHistory();

  const {
    SelectFilterPanel: ForwardingManagerSelectFilterPanel,
    selectFilter: forwardingManagerSelectFilter,
  } = useSelectFilter({
    defaultValue: businessManagementFilter?.adminId ?? forwardingManagerAdminId,
    selectFilterOptionList: [
      { label: "전체", value: 0 },
      ...forwardingManagerAdminList,
    ],
    label: "담당자",
    setHistoryData: onForwardingManagerFilterHistoryChange,
  });

  const { data: businessManagementInfo } =
    BUSINESS_MANAGEMENT_QUERY.useGetBusinessManagementInfo({
      id: forwardingManagerSelectFilter?.value ?? forwardingManagerAdminId,
    });

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
        p: 1,
        background: "#fff",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <Typography>담당자명:</Typography>

        {ForwardingManagerSelectFilterPanel}
      </Box>

      <Divider orientation="vertical" flexItem />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Typography>화주사(배정):</Typography>

          <Button
            onClick={() => {
              // TODO: 기존에 state에 포워딩매니저 아이디와 유저타입을 전달해서 이동하고 있었음 확인 후 추가
              history.push(`/users/list`);
            }}
            sx={{ minWidth: "auto", p: "4px" }}
            variant="text"
          >
            {businessManagementInfo?.customerCnt}
          </Button>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Typography>진행중:</Typography>

          <Button
            onClick={() => {
              // TODO: 기존에 state에 포워딩매니저 아이디와 유저타입을 전달해서 이동하고 있었음 확인 후 추가
              history.push(`/bid`);
            }}
            sx={{ minWidth: "auto", p: "4px" }}
            variant="text"
          >
            {businessManagementInfo?.proceedCnt}
          </Button>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Typography>진행건수:</Typography>

          <Typography>{businessManagementInfo?.proceedBidCnt}</Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Typography>{THIS_YEAR_AS_TWO_DIGITS}년도 누적 수주액:</Typography>

          <Typography>
            {toThousandUnitFormat(
              Math.floor(businessManagementInfo?.yearSalesAmt || 0)
            )}
            원
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Typography>당월 수주액:</Typography>

          <Typography>
            {toThousandUnitFormat(
              Math.floor(businessManagementInfo?.monthSalesAmt || 0)
            )}
            원
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default ManagerSummary;
