import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_COMMON_QUERY";
import LegacyTable from "@sellernote/_shared-for-forwarding-admin/src/components/LegacyTable";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";
import { useAdminFareTable } from "@sellernote/_shared-for-forwarding-admin/src/hooks/useAdminFareTable";

const FareManageFclList = () => {
  const { data: portList } = ADMIN_COMMON_QUERY.useGetPortList();

  const { headCells, Rows, pagination } = useAdminFareTable({
    portList,
    freightType: "FCL",
  });

  return (
    <Layout breadcrumbs={["운임 관리"]} title="FCL운임 관리">
      {headCells && Rows && (
        <LegacyTable
          title="FCL 운임관리 리스트"
          headCells={headCells}
          rows={Rows}
          pagination={pagination}
        />
      )}
    </Layout>
  );
};

export default withRequireAuth(FareManageFclList);
